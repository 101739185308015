import React from 'react';
import Header from './components/Header';
import NavBar from './components/NavBar';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Subscribe from './pages/Subscribe';
import Footer from './components/Footer';
import Production from './pages/Production';
import Vente from './pages/Vente';
import Trace from './pages/Trace';
import Utilisateur from './pages/Utilisateur';
import Benefice from './pages/Benefice'
import Depense from './pages/Depense';

// Approvisionnements
import { Achat, NewAchat, ViewAchat } from './pages/approvisionnement/achat'
import { Commande, NewCommande, ViewCommande } from './pages/approvisionnement/commande'
import { Matiere } from './pages/approvisionnement/matiere';
import {Stock as StockMatiere } from './pages/approvisionnement/stock'

// Produits finis
import { Produit } from './pages/produit-fini/produit';
import { Stock as StockProduit } from './pages/produit-fini/stock';


import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import AddProduction from './pages/Production/Add';
import DetailProduction from './pages/Production/Detail';

import { ProduitsServices, BilansServices, MatieresServices, ProductionsServices, VentesServices, UtilisateursServices, DevisServices, UnitesServices,
  LoginServices, RolesServices, StocksServices, TracesServices, StatsServices, ClientsServices, DepensesServices, StructuresServices, ApprovisionnementsServices, MagasinsServices, ConnexionServices, StoresServices, DevisesServices } from './services'
import Role from './pages/Role';
import Profil from './pages/Profil';
import AddVente from './pages/Vente/Add';
import Client from './pages/Client';
import Devis from './pages/Devis';
import AddDevis from './pages/Devis/Add';
import Structure from './pages/Structure';

import DetailVente from './pages/Vente/Detail';
import DetailClient from './pages/Client/Detail';
import Expire from './pages/Expire';
import NotFound from './pages/NotFound';
import Unite from './pages/Unite';
import Store from './pages/Store';
import Devise from './pages/Devise';

const App = ({session}) => {
  const services = {
    produit: new ProduitsServices(session),
    matiere: new MatieresServices(session),
    client: new ClientsServices(session),
    depense: new DepensesServices(session),
    devis: new DevisServices(session),
    devise: new DevisesServices(session),
    production: new ProductionsServices(session),
    approvisionnement: new ApprovisionnementsServices(session),
    magasin: new MagasinsServices(session),
    stat: new StatsServices(session),
    vente: new VentesServices(session),
    user: new UtilisateursServices(session),
    login: new LoginServices(session),
    connexion: new ConnexionServices(session),
    stock: new StocksServices(session),
    store: new StoresServices(session),
    unite: new UnitesServices(session),
    bilan: new BilansServices(session),
    trace: new TracesServices(session),
    role: new RolesServices(session),
    parametres: new LoginServices(session),
    structure: new StructuresServices(session)
  }
  
  const username = session.user ? session.user['name'] : ''
  const role = session.user ? session.user['role'] : ''
  const permissions = session.user ? session.user['permissions'] ? JSON.parse(session.user['permissions']) : {} : {}
  const baseUrl = session.baseUrl ? `/${session.baseUrl}` : ''

  const routesAdmin = [
    {name: 'Accueil', path: `${baseUrl}/`, props: { controller: services.stat }, component: permissions.dashboard === '1' ? Home : NotFound  },
    {name: 'Accueil', path: `${baseUrl}/home`, props: { controller: services.stat }, component: permissions.dashboard === '1' ? Home : NotFound },

    // DEBUT Approvisionnement 
    {name: "Identification des matières premières", path: `${baseUrl}/matieres`, props: {controller: services.matiere, uniteController: services.unite}, component: permissions.matieres === '1' ? Matiere : NotFound },

    {name: "Achat de matière première", path: `${baseUrl}/approvisionnements`, props: {controller: services.approvisionnement }, component: permissions.matieres === '1' ? Achat : NotFound },
    {name: 'Achat de matière première', path: `${baseUrl}/approvisionnements-new`, props: {controller: services.approvisionnement, matiereController: services.matiere, storeController: services.store}, component: permissions.matieres === '1' ? NewAchat : NotFound },
    {name: 'Achat de matière première', path: `${baseUrl}/approvisionnements/:id`, props: {controller: services.approvisionnement}, component: permissions.matieres === '1' ? ViewAchat : NotFound },
    
    {name: "Commande de matière première", path: `${baseUrl}/commandes`, props: {controller: services.approvisionnement }, component: permissions.matieres === '1' ? Commande : NotFound },
    {name: 'Commande de matière première', path: `${baseUrl}/commandes-new`, props: {controller: services.approvisionnement, matiereController: services.matiere, storeController: services.store}, component: permissions.matieres === '1' ? NewCommande : NotFound },
    {name: 'Commande de matière première', path: `${baseUrl}/commandes/:id`, props: {controller: services.approvisionnement}, component: permissions.matieres === '1' ? ViewCommande : NotFound },
    
    {name: "Stock des matières premières", path: `${baseUrl}/magasins`, props: {controller: services.approvisionnement, storeController: services.store}, component: permissions.matieres === '1' ? StockMatiere : NotFound },
    {name: "Magasins", path: `${baseUrl}/matiere-stores`, props: {controller: services.store, storeType: 'matiere'}, component: permissions.matieres === '1' ? Store : NotFound },

    // FIN Approvisionnemnt

    // DEBUT Produits finis
    {name: 'Identification du produit fini', path: `${baseUrl}/produits`, props: {controller: services.produit, uniteController: services.unite}, component: permissions.produits === '1' ? Produit : NotFound },

    {name: "Magasins", path: `${baseUrl}/produit-stores`, props: {controller: services.store, storeType: 'produit'}, component: permissions.stock === '1' ? Store : NotFound },
    {name: "Stock des produits finis", path: `${baseUrl}/stock`, props: {controller: services.production, storeController: services.store}, component: permissions.stock === '1' ? StockProduit : NotFound},
    // DEBUT Produits finis

    {name: 'Productions', path: `${baseUrl}/productions`, props: {controller: services.production}, component: permissions.productions === '1' ? Production : NotFound },
    {name: 'Productions', path: `${baseUrl}/productions-new`, props: {controller: services.production, produitController: services.produit, approvisionnementController: services.approvisionnement, storeController: services.store, uniteController: services.unite}, component: permissions.productions === '1' ? AddProduction : NotFound },
    {name: 'Productions', path: `${baseUrl}/productions/:id`, props: {controller: services.production}, component: permissions.productions === '1' ? DetailProduction : NotFound },

    // {name: 'Stock', path: `${baseUrl}/stock`, props: {controller: services.stock}, component: permissions.stock === '1' ? Stock : NotFound },

    {name: 'Ventes', path: `${baseUrl}/ventes`, props: {controller: services.vente}, component: permissions.ventes === '1' ? Vente : NotFound },
    {name: 'Ventes', path: `${baseUrl}/ventes-new`, props: {controller: services.vente, stockController: services.stock, clientController: services.client, storeController: services.store, productionController: services.production}, component: permissions.ventes === '1' ? AddVente : NotFound },
    {name: 'Ventes', path: `${baseUrl}/ventes/:id`, props: {controller: services.vente}, component: permissions.ventes === '1' ? DetailVente : NotFound },

    {name: 'Dévis/Facture proforma', path: `${baseUrl}/devis`, props: {controller: services.devis}, component: permissions.devis === '1' ? Devis : NotFound },
    {name: 'Dévis/Facture proforma', path: `${baseUrl}/devis-new`, props: {controller: services.devis, produitController: services.produit, clientController: services.client}, component: permissions.devis === '1' ? AddDevis : NotFound },
    
    {name: 'Charges fixes', path: `${baseUrl}/depenses`, props: {controller: services.depense}, component: permissions.depenses === '1' ? Depense : NotFound },
    
    {name: 'Résultats', path: `${baseUrl}/benefices`, props: {controller: services.stat}, component: permissions.benefices === '1' ? Benefice : NotFound },
    
    {name: 'Traces', path: `${baseUrl}/traces`, props: {controller: services.trace}, component: permissions.traces === '1' ? Trace : NotFound },
    
    {name: 'Clients', path: `${baseUrl}/clients`, props: {controller: services.client}, component: permissions.clients === '1' ? Client : NotFound },
    {name: 'Opérations du client', path: `${baseUrl}/clients/:id`, props: {controller: services.vente}, component: permissions.clients === '1' ? DetailClient : NotFound },

    {name: 'Unités de mesure', path: `${baseUrl}/unites`, props: {controller: services.unite}, component: permissions.utilisateurs === '1' ? Unite : NotFound },
    {name: 'Devise', path: `${baseUrl}/devises`, props: {controller: services.devise}, component: permissions.utilisateurs === '1' ? Devise : NotFound },
    
    {name: 'Utilisateurs', path: `${baseUrl}/utilisateurs`, props: {controller: services.user, roleController: services.role }, component: permissions.utilisateurs === '1' ? Utilisateur : NotFound },
    
    {name: 'Rôles', path: `${baseUrl}/roles`, props: {controller: services.role}, component: permissions.roles === '1' ? Role : NotFound },
    
    {name: "Structure", path: `${baseUrl}/structure`, props: { controller: services.structure, }, component: permissions.structure === '1' ? Structure : NotFound },
    
    {name: "Profil", path: `${baseUrl}/profil`, props: { controller: services.user, }, component: Profil },
    
    {name: "", path: `${baseUrl}/expire`, props: { }, component: Expire },
    {name: "", path: `${baseUrl}/*`, props: { }, component: NotFound },
  ]

  if(session.org === null){
    return <Subscribe controller={services.connexion} />
  }else{
    if(`/${window.location.pathname.split('/')[1]}` !== baseUrl){
      return window.location = permissions.dashboard === '0' ? `${baseUrl}/profil` : baseUrl
    }else{
      if(window.location.pathname === `${baseUrl}` &&  session.user === null){
        return <Login controller={services.login} />
      }else{
        return (
          <Router>
            <Header username={username} baseUrl={baseUrl} />
            <NavBar role={role} permissions={permissions} baseUrl={baseUrl} expired={new Date().getTime() > new Date(session.org.fin).getTime() ? true : false} />
            <Switch>
              <Route path="/home">
                <Redirect to={`${baseUrl}/home`} />
              </Route>
  
              {routesAdmin.map((r,i)=>{
                return(
                  <Route exact path={r.path} key={i} basename={baseUrl} render={(props)=> React.createElement(r.component, {name: r.name, ...props, ...r.props}, null)}  />
                )
              }) }
            </Switch>
            <Footer />
          </Router>
        );
      }
    }
  }
}

export default App