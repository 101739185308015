import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Bar } from 'react-chartjs-2'
import Head from '../../components/Head'
import { formatNumber }  from '../../lib/functions'
import Spinner from '../../components/Spinner'

const anneeOptions = ['2021', '2022', '2023', '2024', '2025','2026', '2027', '2028', '2029', '2030', '2031']
const moisOptions = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Semptembre', 'Octobre', 'Novembre', 'Decembre']

export default class Benefice extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            data: {production: 0, vente: 0, depense: 0},
            filterData: {production: 0, vente: 0, depense: 0},
            isFilter: false, isYearFilter: false, isMonthFilter: false,
            gSpin: false, ySpin: false, mSpin: false,
            debut: '', fin: '', mois: '', annee: '', yearAnnee: '',
            month: {commandes: [], depenses: [], ventes: []},
            year: {commandes: [], depenses: [], ventes: []}
        }
        this.handleFilter = this.handleFilter.bind(this)
        this.handleYearFilter = this.handleYearFilter.bind(this)
        this.handleMonthFilter = this.handleMonthFilter.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }

    getMonth(date){
        this.props.controller.month({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ month: res, mSpin: false }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    getYear(date){
        this.props.controller.year({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ year: res, ySpin: false }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    handleFilter(e){
        e.preventDefault();
        const { debut, fin } = this.state
        const data = { debut, fin }
        this.setState({ gSpin: true })
        this.props.controller.save(data)
        .then(res=>{
            console.log(res)
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ filterData: res, isFilter: true, gSpin: false })
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    handleYearFilter(e){
        e.preventDefault();
        const { yearAnnee } = this.state
        this.setState({ ySpin: true })
        if(yearAnnee !==''){ this.getYear(yearAnnee) }
        else{ toast.error("Le champ Année est obligatoire.") }
    }

    handleMonthFilter(e){
        e.preventDefault();
        this.setState({ mSpin: true })
        const { mois, annee } = this.state
        if(mois !=='' && annee !==''){ this.getMonth({ month: mois, year: annee }) }
        else{ toast.error("Les deux champs (Mois & Année) sont obligatoires.") }
    }

    componentDidMount(){
        const date = new Date()
        this.props.controller.fetch()
        .then(data=>{
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data })
            }
            else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite.") }
        }).catch(()=> toast.error("Une erreur inattendue s'est produite.") )
        this.getMonth({month: date.getMonth()+1, year: `${date.getFullYear()}` })
        this.getYear(`${date.getFullYear()}`)
    }

    render() {
        const { name } = this.props
        const { debut, fin, mois, annee, isFilter, data, filterData, month, year, gSpin, ySpin, mSpin } = this.state

        const barYear = {
            labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Semptembre', 'Octobre', 'Novembre', 'Decembre'],
            datasets: [
               { label: 'Ventes', backgroundColor: '#32CD32', borderColor: '#32CD32', borderWidth: 1, hoverBackgroundColor: '#32CD32', hoverBorderColor: '#32CD32',
                data: year.ventes,
              },
              {label: 'Commandes', backgroundColor: 'yellow', borderColor: 'yellow', borderWidth: 1, hoverBackgroundColor: 'yellow', hoverBorderColor: 'yellow',
               data: year.commandes,
             },
              {label: 'Charges fixes', backgroundColor: 'red', borderColor: 'red', borderWidth: 1, hoverBackgroundColor: 'red', hoverBorderColor: 'red',
                data: year.depenses,
              }
            ]
        }

        const barMonth = {
            labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            datasets: [
               {
                label: 'Ventes', backgroundColor: '#32CD32', borderColor: '#32CD32', borderWidth: 1, hoverBackgroundColor: '#32CD32', hoverBorderColor: '#32CD32',
                data: month.ventes,
              },
              {
               label: 'Commandes', backgroundColor: 'yellow', borderColor: 'yellow', borderWidth: 1, hoverBackgroundColor: 'yellow', hoverBorderColor: 'yellow',
               data: month.commandes,
             },
              {
                label: 'Charges fixes', backgroundColor: 'red', borderColor: 'red', borderWidth: 1, hoverBackgroundColor: 'red', hoverBorderColor: 'red',
                data: month.depenses,
              }
            ]
        }
        return (
            <Head name={name}>
                <div className="content-body">
                    <div className="row">
                        <ToastContainer />
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Filtrer entre deux dates</h4>
                                </div>
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <form method="POST" onSubmit={this.handleFilter}>
                                            <fieldset className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <input onChange={(e) => this.handleChange(e, 'debut')} type="date" value={debut} className="form-control" required />
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <input className="form-control" onChange={(e) => this.handleChange(e, 'fin')} type="date" value={fin} required />
                                                    </div>
                                                    <div className="col-md-4 col-lg-6">
                                                        {!gSpin && <button type="submit" name="valider" className="btn btn-primary" style={{width: 'auto'}}>Filter</button>}
                                                        {gSpin && <Spinner />}
                                                        {isFilter &&
                                                        <button onClick={()=>this.setState({isFilter: false, debut: '', fin: ''})} style={{marginLeft: 8}} className="btn btn-secondary">Annuler</button>} 
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{marginBottom: 24 }}>
                            {isFilter && 
                            <span className="card-text">
                                {`Les totaux des opérations déroulées entre ${debut} et ${fin}.`}
                            </span>}
                            {!isFilter &&
                            <span className="card-text">
                                {`Les totaux des opérations déroulées depuis la mise en place de l'application.`}
                            </span>}
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted warning position-absolute p-1">Charges liées à la production</h5>
                                    <div>
                                        <i className="ft-shopping-cart warning font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3  ">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.production}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.production}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted primary position-absolute p-1">Approvisionnement</h5>
                                    <div>
                                        <i className="la la-truck primary font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.approvisionnement}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.approvisionnement}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted info position-absolute p-1">Ventes</h5>
                                    <div>
                                        <i className="ft-activity info font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.vente}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.vente}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted danger position-absolute p-1">Charges fixes</h5>
                                    <div>
                                        <i className="ft-pie-chart danger font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.depense}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.depense}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted success position-absolute p-1">Bénéfices réels</h5>
                                    <div>
                                        <i className="ft-credit-card success font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(data.vente) - (parseInt(data.production) + parseInt(data.depense) + parseInt(data.approvisionnement))}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(filterData.vente) - (parseInt(filterData.production) + parseInt(filterData.depense)+ parseInt(filterData.approvisionnement))}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted success position-absolute p-1">Bénéfices prévisionnels</h5>
                                    <div>
                                        <i className="ft-credit-card success font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(data.prevision) - (parseInt(data.production) + parseInt(data.depense)+ parseInt(data.approvisionnement))}`)}</h3>}
                                        {isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(filterData.prevision) - (parseInt(filterData.production) + parseInt(filterData.depense)+ parseInt(filterData.approvisionnement))}`)}</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{marginBottom: 12}}>
                            <form method="POST" onSubmit={this.handleMonthFilter}>
                                <div className="row">
                                    <div className="col-12" style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                        <span className="card-text">Séléctionner un mois et une année pour filtrer les statistiques les correspondant</span>
                                        <select className="custom-select" onChange={(e) => this.handleChange(e, 'mois')}  style={{margin: '0px 16px', width: 200, height: 32}} required>
                                            <option value="">--Mois--</option>
                                            {moisOptions.map((a, i) => <option value={i+1} key={i}>{a}</option>)}
                                        </select>
                                        <select className="custom-select" onChange={(e) => this.handleChange(e, 'annee')}  style={{margin: '0px 16px', width: 200, height: 32}} required>
                                            <option value="">--Annee--</option>
                                            {anneeOptions.map((a, i) => <option value={a} key={i}>{a}</option>)}
                                        </select>
                                        {!mSpin && <button type="submit" name="valider" style={{padding: '0 12px', height: 32}} className="btn btn-primary">Filtrer</button>}
                                        {mSpin && <Spinner />}
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Les statistiques journalieres d'un mois de l'année</h4>
                                </div>
                                <div className="card-content">
                                    <div className="card-body">
                                        <Bar data={barMonth} height={150} options={{ responsive: true, maintainAspectRatio: true, }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{marginBottom: 12}}>
                            <div className="row">
                                <div className="col-12">
                                    <form method="POST" onSubmit={this.handleYearFilter}>
                                        <div className="row">
                                            <div className="col-12" style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                                <span className="card-text">Séléctionner une année pour filtrer les statistiques de celle-ci</span>
                                                <select className="custom-select" onChange={(e) => this.handleChange(e, 'yearAnnee')}  style={{margin: '0px 16px', width: 200, height: 32}} required>
                                                    <option value="">--Annee--</option>
                                                    {anneeOptions.map((a, i) => <option value={a} key={i}>{a}</option>)}
                                                </select>
                                                {!ySpin && <button type="submit" name="valider" style={{padding: '0 12px', height: 32}} className="btn btn-primary">Filtrer</button>}
                                                {ySpin && <Spinner />}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Les statistiques mensuelles d'une année</h4>
                                </div>
                                <div className="card-content">
                                    <div className="card-body">
                                        <Bar data={barYear} height={150} options={{ responsive: true, maintainAspectRatio: true, }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Head>
        )
    }
}