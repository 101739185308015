import { EntityService } from './service';

export class MatieresServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/matieres', session)
  }
  findById = (id) => this._get(`${id}`)
  select = () => this._get(`/selectables`)
}

export class ProduitsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/produits', session)
  }
  findById = (id) => this._get(`${id}`)
}


export class ClientsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/clients', session)
  }
}

export class DepensesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/depenses', session)
  }
  ByYear = (year) => this._get(`by/${year}`)
}

export class DevisServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/devis', session)
  }
  details = (id) => this._get(`details/${id}`)
}

export class DevisesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/devises', session)
  }
}

export class ProductionsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/productions', session)
  }
  findById = (id) => this._get(`${id}`)
  details = (id) => this._get(`details/${id}`)
  stock = (type) => this._get(`/produits/${type}`)
  stockSelectableByStore = (store) => this._get(`stock/selectable/${store}`)
}

export class ApprovisionnementsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/approvisionnements', session)
  }
  commandes = (id) => this._get(`commandes`)
  ship = (data) => this._post(`commandes/ship`, { data })
  shipQuantity = (data) => this._post(`commandes/ship/quantity`, { data })
  findById = (id) => this._get(`${id}`)
  details = (id) => this._get(`details/${id}`)
  stock = (type) => this._get(`matieres/${type}`)
  stockSelectableByStore = (store) => this._get(`stock/selectable/${store}`)
  savePaiement = (data) => this._post(`/paiements/create`, { data })
  updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)

  updateStock = (id, data) => this._post(`/stock/update/${id}`, { data })
}



export class VentesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/ventes', session)
  }
  findById = (id) => this._get(`${id}`)
  findByClient = (id) => this._get(`client/${id}`)
  details = (id) => this._get(`details/${id}`)
  savePaiement = (data) => this._post(`/paiements/create`, { data })
  updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)
}

export class UnitesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/unites', session)
  }
  findById = (id) => this._get(`${id}`)
}

export class UtilisateursServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/users', session)
  }
  findById = (id) => this._get(`${id}`)
  updatePassword = (id, data) => this._post(`/update-profil/${id}`, { data })
}

export class LoginServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/signin', session)
  }
}

export class ConnexionServices extends EntityService {
  constructor(session) {
    super('connexions', session)
  }
}


export class RolesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/roles', session)
  }
  setPermissions = (id, data) => this._post(`/permissions/${id}`, { data })
}

export class StocksServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/stock', session)
  }
  getProduit = () => this._get('produits')
  printAll = () => this._report(`prints/all`)
}

export class StoresServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/stores', session)
  }
  getStores = (storeType) => this._get(`${storeType}`)
  delete = (id, storeType) =>this._delete(`${id}/${storeType}`)
}

export class MagasinsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/magasin', session)
  }
  printAll = () => this._report(`prints/all`)
}

export class TracesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/trace', session)
  }
  printAll = () => this._report(`prints/all`)
}

export class BilansServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/bilans', session)
  }
}

export class StatsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/stats', session)
  }
  card = () => this._get(`/card`)
  epuise = () => this._get(`/epuise`)
  critique = () => this._get(`/critique`)
  
  week = (data) => this._post(`/week`, {data})
  month = (data) => this._post(`/month`, {data})
  year = (data) => this._post(`/year`, {data})
}

export class StructuresServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/structures', session)
  }
  update = (file, body) => this._import(`update`, file, body)
}