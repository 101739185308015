import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Session from '../../session'
import Spinner from '../../components/Spinner'
import logo from '../../assets/img/logo.png'
import './index.css'

const initialState = { dbname: '', key: '' }
export default class Subscribe extends React.Component{
    constructor(props) {
        super(props)
        this.state = { load: false, connect: true, ...initialState }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleSubmit = e =>{
        e.preventDefault()
        this.setState({ load: true })
        const { dbname } = this.state
        const data = { dbname }

        this.props.controller.save(data)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                if(res.id){
                    Session.config(res)
                    window.location = '/'+res._id
                }else{
                    toast.error("Une erreur inattendue s'est produit.")
                    this.setState({ load: false })
                }
            } else {
                this.setState({ load: false })
                toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produit.")
            }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })
        
    }
    render(){
        const { load, connect } = this.state
        return(
            <div className="app-content">
                <div className="content-wrapper">
                    <ToastContainer />
                    <div className='row' style={{marginLeft: 0, marginRight: 0, height: '100vh', marginTop: '-5rem'}}>
                        <div className="col-ms-12 col-lg-5 ">
                            <div className='nx-login-form-container'>
                                <div className="nx-login-form-content card-body login-card-body">
                                    <div className="login-logo">
                                        <img src={logo} alt="logo" width="150" />
                                    </div>

                                    <h1 className='nx-login-welcome'>BIENVENUE</h1>
                                    <div className='nx-login-msg-container'>
                                        <div className='nx-mi-divier'></div>
                                        <span style={{ marginLeft: 8, marginRight: 8 }}>CONNEXION A VOTRE ESPACE</span>
                                        <div className='nx-mi-divier'></div>
                                    </div>
                                    {connect &&
                                    <form onSubmit={this.handleSubmit}>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" onChange={(e)=>this.handleChange(e, 'dbname')} placeholder="Nom de l'espace de travail" required />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            {load && <Spinner />}
                                            {!load && <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>Suivant</button>}
                                            {/* {!load && <button className="btn btn-link" style={{marginTop: "24px !important"}} >Demander un accès pour la version de d'éssaie de 30 jours</button>} */}
                                        </fieldset>
                                    </form>}
                                    {!connect &&
                                    <form>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" onChange={(e)=>this.handleChange(e, 'login')} placeholder="Nom de l'espace de travail" required />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            <input type="password" className="form-control" onChange={(e)=>this.handleChange(e, 'password')} placeholder="Mot de passe" required />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            {load && <Spinner />}
                                            {!load && <button className="btn btn-primary" style={{ width: '100%' }}>Demander</button>}
                                            </fieldset>
                                    </form>}

                                    <div className='nx-login-divier'></div>
                                    <p style={{fontSize: 12}}>Vous n'avez pas d'espace de travail ? <a href='#'>Créer un nouvel espace</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-ms-12 col-lg-7 nx-login-description-content" >
                            <div className='nx-login-description-wraper'>
                                <button className='btn nx-login-policy-btn'>
                                    <span className="la la-book" style={{marginRight: 8}}></span>Politique de confidentialité
                                </button>
                                <p>
                                    Renseigner vos informations d'identification pour acceder à votre espace de travail.<br /><br />
                                    Ces informations vous sont fournies par mail après la creation de votre compte
                                </p>
                                <a href='https://sanifere.codesign.ml' target='_blank' rel="noreferrer" className='btn nx-login-discover-btn'>Decouvrez SANI-FERE</a>
                            </div>
                            <div className='nx-login-description'></div>
                        </div>
                    </div>
            </div>
        </div>
        )
    }
}