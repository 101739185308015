import React from "react"
import { Link } from 'react-router-dom'
import session from '../session'
import user from '../assets/img/user.png'

export default class Header extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            cls: ''
        }
    }

    handleLogout(){
        session.remove('$sb_user')
        window.location = this.props.baseUrl
    }

    toggleNav(){
        let body = document.getElementById('nx-spbody')
        if(body.className === 'vertical-layout 2-columns fixed-navbar pace-done menu-hide vertical-overlay-menu'){
            body.className = 'vertical-layout 2-columns fixed-navbar pace-done vertical-overlay-menu menu-open'
        }else{
            body.className = 'vertical-layout 2-columns fixed-navbar pace-done menu-hide vertical-overlay-menu'
        }
        this.setState({cls: body.className })
    }

    updateDimensions = () => {
        let body = document.getElementById('nx-spbody')
        if(window.innerWidth <= 900){
            body.className = 'vertical-layout 2-columns fixed-navbar pace-done menu-hide vertical-overlay-menu'
        }else{
            body.className = 'vertical-layout 2-columns fixed-navbar pace-done vertical-menu menu-expanded'
        }
        this.setState({cls: body.className })
    };

      
    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
        const { cls } = this.state
        const { username, baseUrl } = this.props
        return (
          <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light">
              <div className="navbar-wrapper">
                  <div className="navbar-container content">
                      <div className="collapse navbar-collapse show" id="navbar-mobile">
                          <ul className="nav navbar-nav mr-auto float-left">
                              {cls !== 'vertical-layout 2-columns fixed-navbar pace-done vertical-menu menu-expanded' &&
                                <li className="nav-item">
                                  <button className="nav-link nav-menu-main menu-toggle nx-nav-toggle" onClick={()=>this.toggleNav()}><i className="ft-menu"></i></button>
                              </li>}
                          </ul>
                          <ul className="nav navbar-nav float-right">
                              {/* <li className="dropdown dropdown-notification nav-item">
                                  <a className="nav-link nav-link-label" href="#" data-toggle="dropdown"><i className="ficon ft-mail"></i></a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                      <div className="arrow_box_right"><a className="dropdown-item" href="#"><i className="ft-book"></i> Read Mail</a><a className="dropdown-item" href="#"><i className="ft-bookmark"></i> Read Later</a><a className="dropdown-item" href="#"><i className="ft-check-square"></i> Mark all Read       </a></div>
                                  </div>
                              </li> */}
                              <li className={`dropdown dropdown-user nav-item ${cls}`}>
                                  <Link className="dropdown-toggle nav-link dropdown-user-link" to="#" onClick={()=>this.setState({cls: this.state.cls === 'show' ? '' : 'show'})}  data-toggle="dropdown">             
                                      <span className="avatar avatar-online">
                                          <img src={user} alt="avatar" />
                                      </span>
                                  </Link>
                                  <div className={`dropdown-menu dropdown-menu-right dropdown-menu ${cls}`}>
                                      <div className="arrow_box_right">
                                          <div className="dropdown-item" href="#">
                                              <span className="avatar avatar-online" style={{fontSize: 12}}>{username}</span>
                                          </div>
                                          <div className="dropdown-divider"></div>
                                          <Link className="dropdown-item" to={`${baseUrl}/profil`}><i className="ft-user"></i>Profil</Link>
                                          <Link className="dropdown-item" to="#" onClick={()=>this.handleLogout()}><i className="ft-power"></i> Deconnexion</Link>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </nav>
        )
    }
}