import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { formatDateComplete } from '../../lib/functions'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const columns = [
    { name: 'action', header: 'Action' },
    { name: 'nom', header: 'Executeur' },
    { name: 'description', header: 'Description' },
    { name: 'date', header: 'Date', date: 'date-complete' }
]

export default class Trace extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            tempData: [],
            load: true
        }
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.action.toLowerCase().includes(value.toLowerCase()) ||
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.description.toLowerCase().includes(value.toLowerCase()) ||
                formatDateComplete(td?.date).toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    componentDidMount(){
        this.props.controller.fetch()
        .then(data=>{ 
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render(){
        const { name } = this.props
        const { data, load } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    data={data}
                                    crud={false}
                                    load={load}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Head>
        )
    }
}