import React from "react";
import { Link } from "react-router-dom";
import navbg from './../assets/img/02.jpg'
import logo from './../assets/img/logo.png'

export default class NavBar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            active: window.location.pathname,
            open: '',
            width: window.innerWidth
        }
    }
    toggleNav(){
        let body = document.getElementById('nx-spbody')
        body.className = 'vertical-layout 2-columns fixed-navbar pace-done menu-hide vertical-overlay-menu'
    }
    handleNavItemClicked = (name) =>{
        this.setState({ active: name })
        if(this.state.width <= 900){
            this.toggleNav()
        }
    }

    updateDimensions = () => this.setState({ width: window.innerWidth })

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handleOpenSubMenu(menu){
        if(menu === this.state.open){
            this.setState({open: ''})
        }else{
            this.setState({open: menu/*, active: `/${menu}`*/ })
        }
    }
    render(){
        const { active, open, width } = this.state
        const { username, role, baseUrl, permissions, expired } = this.props
        
      return (
        <div className="main-menu menu-fixed menu-light menu-accordion  menu-shadow " data-scroll-to-active="true" data-img="theme-assets/images/backgrounds/02.jpg" style={{background: `url(${navbg})` }}>
            <div className="navbar-header">
              <ul className="nav navbar-nav flex-row">       
                <li className="nav-item mr-auto">
                    <Link className="navbar-brand" to={`${baseUrl}/home`} onClick={()=>this.handleNavItemClicked("/home")}>
                        <img className="brand-logo" alt="logo" src={logo} style={{ width: 94 }} />
                    </Link>
                </li>
                {width <= 900 &&
                    <li className="nav-item">
                        <button onClick={()=>this.toggleNav()} className="nav-link close-navbar nx-nav-toggle" ><i className="ft-x"></i></button>
                    </li>
                }
              </ul>
            </div>
            <div className="main-menu-content">
                {!expired &&
                <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                    {permissions.dashboard === '1' && 
                    <li className={(active === "/home") ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/home`} onClick={()=>this.handleNavItemClicked("/home")}>
                            <i className="ft-home"></i>
                            <span className="menu-title">Tableau de bord</span>
                        </Link>
                    </li>}

                    {permissions.depenses === '1' &&
                    <li className={('/depenses' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/depenses`} onClick={()=>this.handleNavItemClicked("/depenses")}>
                            <i className="la la-dollar"></i>
                            <span className="menu-title">Charges fixes</span>
                        </Link>
                    </li>}

                    {/* {permissions.matieres === '1' &&
                    <li className={(active === "/matieres") ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/matieres`} onClick={()=>this.setState({ active: "/matieres" })}>
                            <i className="la la-flask"></i>
                            <span className="menu-title">Matières premières</span>
                        </Link>
                    </li>} */}

                    {permissions.matieres === '1' &&
                    <li className={`has-sub ${open === 'approvisionnement' ? 'open active' : 'nav-item'}`}>
                        <Link to="#" onClick={()=>this.handleOpenSubMenu('approvisionnement')}>
                            <i className="la la-truck"></i>
                            <span className="menu-title">Approvisionnement</span>
                        </Link>
                        <ul className="menu-content">
                            <li className={(active === "/matieres") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/matieres`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/matieres")}>
                                    Identification
                                </Link>
                            </li>
                            <li className={(active === "/approvisionnements") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/approvisionnements`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/approvisionnements")}>
                                    Achat
                                </Link>
                            </li>
                            <li className={(active === "/commandes") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/commandes`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/commandes")}>
                                    Commandes
                                </Link>
                            </li>
                            <li className={(active === "/magasins") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/magasins`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/magasins")}>
                                    Stock
                                </Link>
                            </li>
                            <li className={(active === "/matiere-stores") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/matiere-stores`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/matiere-stores")}>
                                    Magasins
                                </Link>
                            </li>
                        </ul>
                    </li>}

                    {permissions.produits === '1' &&
                    <li className={`has-sub ${open === 'produits' ? 'open active' : 'nav-item'}`}>
                        <Link to="#" onClick={()=>this.handleOpenSubMenu('produits')}>
                            <i className="la la-cubes"></i>
                            <span className="menu-title">Produits finis</span>
                        </Link>
                        <ul className="menu-content">
                            <li className={(active === "/produits") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/produits`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/produits")}>
                                    Identification
                                </Link>
                            </li>
                            <li className={(active === "/stock") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/stock`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/stock")}>
                                    Stock
                                </Link>
                            </li>
                            <li className={(active === "/produit-stores") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/produit-stores`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/produit-stores")}>
                                    Magasins
                                </Link>
                            </li>
                        </ul>
                    </li>}

                    {/* {permissions.produits === '1' &&
                    <li className={('/produits' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/produits`} onClick={()=>this.handleNavItemClicked("/produits")}>
                            <i className="ft-box"></i>
                            <span className="menu-title">Ident. du produit fini</span>
                        </Link>
                    </li>} */}

                    {permissions.productions === '1' &&
                    <li className={('/productions' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/productions`} onClick={()=>this.handleNavItemClicked("/productions")}>
                            <i className="la la-cogs"></i>
                            <span className="menu-title">Productions</span>
                        </Link>
                    </li>}

                    {/* {permissions.stock === '1' &&
                    <li className={('/stock' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/stock`} onClick={()=>this.handleNavItemClicked("/stock")}>
                            <i className="ft-layers"></i>
                            <span className="menu-title">Stock de produits finis</span>
                        </Link>
                    </li>} */}
                    {/* {permissions.stock === '1' &&
                    <li className={('/produit-store' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/produit-store`} onClick={()=>this.handleNavItemClicked("/produit-store")}>
                            <i className="ft-layers"></i>
                            <span className="menu-title">Magasin de produits finis</span>
                        </Link>
                    </li>} */}

                    {permissions.clients === '1' &&
                    <li className={('/clients' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/clients`} onClick={()=>this.handleNavItemClicked("/clients")}>
                            <i className="la la-users"></i>
                            <span className="menu-title">Clients</span>
                        </Link>
                    </li>}

                    {permissions.ventes === '1' &&
                    <li className={('/ventes' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/ventes`} onClick={()=>this.handleNavItemClicked("/ventes")}>
                            <i className="la la-shopping-cart"></i>
                            <span className="menu-title">Ventes</span>
                        </Link>
                    </li>}

                    {permissions.devis === '1' &&
                    <li className={('/devis' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/devis`} onClick={()=>this.handleNavItemClicked("/devis")}>
                            <i className="la la-commenting"></i>
                            <span className="menu-title">Dévis/Facture proforma</span>
                        </Link>
                    </li>}
                    
                    {permissions.benefices === '1' &&
                    <li className={('/benefices' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/benefices`} onClick={()=>this.handleNavItemClicked("/benefices")}>
                            <i className="la la-money"></i>
                            <span className="menu-title">Résultats</span>
                        </Link>
                    </li>}

                    {/* {permissions.traces === '1' &&
                    <li className={('/traces' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/traces`} onClick={()=>this.handleNavItemClicked("/traces")}>
                            <i className="la la-hand-paper-o"></i>
                            <span className="menu-title">Traces</span>
                        </Link>
                    </li>} */}
                    {/* 
                    {permissions.utilisateurs === '1' &&
                    <li className={('/utilisateurs' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/utilisateurs`} onClick={()=>this.handleNavItemClicked("/utilisateurs")}>
                            <i className="la la-users"></i>
                            <span className="menu-title">Utilisateurs</span>
                        </Link>
                    </li>} */}

                    {/* {permissions.roles === '1' &&
                    <li className={('/roles' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/roles`} onClick={()=>this.handleNavItemClicked("/roles")}>
                            <i className="la la-cubes"></i>
                            <span className="menu-title">Rôles & Permissions</span>
                        </Link>
                    </li>} */}

                    {/* {permissions.utilisateurs === '1' &&
                    <li className={('/unites' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/unites`} onClick={()=>this.handleNavItemClicked("/unites")}>
                            <i className="la la-cube"></i>
                            <span className="menu-title">Unités</span>
                        </Link>
                    </li>} */}

                    {/* {permissions.structure === '1' &&
                    <li className={('/structure' === active) ? 'active' : 'nav-item'}>
                        <Link to={`${baseUrl}/structure`} onClick={()=>this.handleNavItemClicked("/structure")}>
                            <i className="la la-building"></i>
                            <span className="menu-title">Structure</span>
                        </Link>
                    </li>} */}

                    {permissions.produits === '1' &&
                    <li className={`has-sub ${open === 'administration' ? 'open active' : 'nav-item'}`}>
                        <Link to="#" onClick={()=>this.handleOpenSubMenu('administration')}>
                            <i className="la la-building"></i>
                            <span className="menu-title">Administration</span>
                        </Link>
                        <ul className="menu-content">
                            <li className={(active === "/utilisateurs") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/utilisateurs`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/utilisateurs")}>
                                    Utilisateurs
                                </Link>
                            </li>
                            <li className={(active === "/roles") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/roles`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/roles")}>
                                    Rôles & Permissions
                                </Link>
                            </li>
                            <li className={(active === "/unites") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/unites`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/unites")}>
                                    Unités de mesure
                                </Link>
                            </li>
                            <li className={(active === "/devises") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/devises`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/devises")}>
                                    Devise
                                </Link>
                            </li>
                            <li className={(active === "/traces") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/traces`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/traces")}>
                                    Traces
                                </Link>
                            </li>
                            <li className={(active === "/structure") ? 'active' : ''}>
                                <Link className="menu-item" to={`${baseUrl}/structure`} style={{fontSize: 11, fontWeight: 500}} onClick={()=>this.handleNavItemClicked("/structure")}>
                                    Structure
                                </Link>
                            </li>
                        </ul>
                    </li>}

                </ul>}
            </div>
            <div className="navigation-background"></div>
        </div>
      );
    }
}
