import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import { Link } from 'react-router-dom'

const paragraphe = {background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {marginBottom: 16, border: '1px solid #ddd',paddingTop: 24, borderRadius: 8, top: -24, position: 'relative'}

export default class DetailClient extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            client: {},
            load: true,
            popLoad: false,
        }
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = sum + (data[i]['puvente'] * data[i]['quantite']) }
        return sum
    }

    gen(data){
        generatePdf(data, 'ventes')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrintOne(id){
        this.setState({popLoad: true})
        const store = Session.get('$sb_structure')
        
        this.props.controller.details(id)
        .then(res=>{
            console.log(res)
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ popLoad: false })
                this.gen({...res, store})
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false  }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false  }) })
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.controller.findByClient(id)
        .then(data => {
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data: data.operations, client: data.client, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render(){
        const { name } = this.props
        const { data, client, popLoad, load  } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{padding: 0, display: 'inline-block'}} onClick={()=>this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{display: 'inline-block'}}>La liste des opérations du client</h4>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body" style={{marginLeft: 14, marginRight: 14}}>
                            <div className="row">
                                <div className="col-12">
                                    <p className="card-text" style={paragraphe}>Les informations du client</p>
                                    <div className="row" style={{...row, marginRight: 8}}>
                                        <div className="col-sm-12">
                                            <p style={{marginBottom: 4}}><strong>Nom : </strong>{client.nom}</p>
                                            <p style={{marginBottom: 4}}><strong>Société : </strong>{client.societe}</p>
                                            <p style={{marginBottom: 4}}><strong>Téléphone : </strong>{client.telephone}</p>
                                            <p style={{marginBottom: 4}}><strong>Email : </strong>{client.email}</p>
                                            <p style={{marginBottom: 4}}><strong>Adresse : </strong>{client.adresse}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p style={paragraphe}>Les opérations du client </p>
                            <div className="row" style={row}>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table" id="datatable">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Facture</th>
                                                    <th>Montant</th>
                                                    <th>Date</th>
                                                    <th>Type d'opération</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length > 0 &&
                                                data.map((d,i)=>{
                                                    return(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{d.id}</td>
                                                            <td>{d.montant}</td>
                                                            <td>{d.date}</td>
                                                            <td>{d.devis === 0 ? <span className="badge bg-success">Facture</span> : <span className="badge bg-secondary">Dévis</span>}</td>
                                                            <td>
                                                                <Link className="btn btn-link" to={`../ventes/${d._id}`} style={{padding: 0}}>
                                                                    <i className="la la-eye"></i>
                                                                </Link>
                                                                <button className="btn btn-link" onClick={()=>this.handlePrintOne(d.id)}>
                                                                    <i className="la la-print"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) }
                                            </tbody>
                                        </table>
                                        {typeof(data.length) === 'undefined' && !load &&
                                        <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center', marginTop: 8}}>
                                            {`Aucune information trouvée !`}
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>

                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>

                </ReactModal>
            </Head>
        )
    }
}