export const file = 
`<html>
    <head>
        <title>::.Gesprod</title>
        <style type="text/css">
            @page 
                {
                    size: auto;   /* auto is the initial value */
                    margin: 0mm;  /* this affects the margin in the printer settings */
                }
            .content {
                width: 100%;
                /*  height: 3480px;*/
                margin: auto;
            }

            th {
                font-style: normal;
                font-size: 14px;
                text-decoration: none;
                text-transform: uppercase;
                border: 1px #ddd solid;
                text-align: center;

            }

            #id {
                font-style: normal;
                font-size: 34px;
                text-decoration: none;
                text-transform: uppercase;
                border: 2px #000 solid;
                text-align: center;

            }

            table {

                width: 100%;
                border: 2px #ddd solid;
                border-collapse: collapse;
            }

            th {
                text-transform: Capitalize
            }

            td {

                border: 1px #ddd solid;
                text-align: center;
                font-size: 12px;
            }
            .paragraphe {background: #fff; z-index: 4; position: relative; display: inline-block; padding: 0px 8px; fontSize: 11px; margin-left: 4px}
            .roww {margin-bottom: 16px; border: 1px solid #ddd; padding: 12px; border-radius: 8px; top: -24; position: relative}

            #lgo,
            #ifo {
                display: inline-block;
            }
        </style>
    </head>

    <body>
        <div style="border: 4px solid #ddd; display: flex; flex-direction: row; justify-content: center; margin-left: 24px; margin-right: 24px; border-radius: 24px;">
            <p id="lgo" style="text-align: left;"><img src="{{store.logoPath}}" width="100" alt="logo" /></br> </p>

            <p style="text-align: center" id="ifo">
                <span
                    style="font-size: 25pt;font-family: 'Times New Roman', Times, serif; color: #1C1570; text-transform: uppercase; font-weight: bold;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{store.nom}}<br>
                </span>
                <span style="font-weight: bold; margin-top: 8px">
                    Tél: {{store.telephone}}</br>
                    E-mail: {{store.email}}</br>
                    {{store.adresse}}
                </span>
            </p>
        </div>
        <p style="text-align: right; margin-right: 24px; font-weight: 400; font-size: 12px">Imprimé le : {{imprimerDate}}</p>

        <div style="margin: 24px; width: 30%">
            <table>
                <thead>
                    <tr style="height: 36px">
                        <th style="width: 30%;">NUMERO</th>
                        <th style="width: 70%;">DATE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="height: 36px">
                        <td style="border-bottom: none">{{facture.id}}</td>
                        <td style="border-bottom: none">{{facture.date}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="row" style="display: flex; flex-direction: row; justify-content: space-between; margin: 0px 24px">
            <div className="col-sm-6" style="width: 48%">
                <p class="paragraphe">Client</p>
                <div className="row" class="roww" style="display: flex; flex-direction: row">
                    <div className="col-sm-12">
                        <p style="margin-Bottom: 4px; font-size: 12px"><strong>Nom : </strong>{{facture.nom}}</p>
                        <p style="margin-Bottom: 4px; font-size: 12px"><strong>Société : </strong>{{facture.societe}}</p>
                        <p style="margin-Bottom: 4px; font-size: 12px"><strong>Téléphone : </strong>{{facture.telephone}}</p>
                        <p style="margin-Bottom: 4px; font-size: 12px"><strong>Email : </strong>{{facture.email}}</p>
                        <p style="margin-Bottom: 4px; font-size: 12px"><strong>Adresse : </strong>{{facture.adresse}}</p>
                    </div>
                    
                </div>
            </div>
            <div className="col-sm-6" style="width: 48%">
                <p class="paragraphe">Montant du dévis </p>
                <div className="row" class="roww" style="display: flex; flex-direction: row">
                    <div className="col-12">
                        <p style="margin-Bottom: 4px; font-size: 12px">Montant du dévis HT : <strong>{{htaxeVente details}} Fcfa</strong></p>
                        <p style="margin-Bottom: 4px; font-size: 12px">Montant de la TVA ({{facture.tva}}%) : <strong>{{tvaVente facture.montant details}} Fcfa</strong></p>
                        <p style="margin-Bottom: 4px; font-size: 12px">Montant du dévis TCC : <strong>{{facture.montant}} Fcfa</strong></p>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin: 24px;">
            <table>
                <thead>
                    <tr style="height: 36px">
                        <th>#</th>
                        <th>Référence</th>
                        <th>Désignation</th>
                        <th>Prix unitaire</th>
                        <th>Quantité</th>
                        <th>Montant</th>
                    </tr>
                </thead>
                <tbody>
                    {{#each details}}
                        <tr>
                            <td>{{inc @index}}</td>
                            <td>{{reference}}</td>
                            <td>{{designation}}</td>
                            <td>{{numberFormater puvente}} F CFA</td>
                            <td>{{numberFormater quantite}} ({{unite}})</td>
                            <td>{{numberFormater2 quantite puvente}}  F CFA</td>
                        </tr>
                    {{/each}}
                </tbody>
            </table>
        </div>

    </body>
</html>`