import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Spinner from '../../../components/Spinner'
import Head from '../../../components/Head'
import Session from '../../../session'
import { generatePdf } from '../../print.controller'
import Table from '../../../components/Table'
import SearchInput from '../../../components/Search'
import ReactModal from 'react-modal'
import { formatEditDate } from '../../../lib/functions'

const columns = [
    { name: 'libelle', header: 'Libellé' },
    { name: 'quantiteRestante', header: 'Quantité', suffix: '' },
    { name: 'unite', header: 'Unité' },
    { name: 'puDetail', header: 'P.U. Détail' },
    { name: 'puGros', header: 'P.U. En Grois' },
    { name: 'store', header: 'Magasin'},
]

const columnsAll = [
    { name: 'reference', header: 'Référence' },
    { name: 'libelle', header: 'Libellé' },
    { name: 'quantiteRestante', header: 'Qté restante', suffix: '' },
    { name: 'unite', header: 'Unité' },
    { name: 'puDetail', header: 'P.U. Détail' },
    { name: 'puGros', header: 'P.U. En Grois' },
    { name: 'expire', header: 'Expire le', date: 'date-fr' },
    { name: 'store', header: 'Magasin'},
]

const initialState = {
    popLoad: false, modalDisplay: false, modal: false
}
export default class Stock extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            tempData: [],
            storeOptions: [],
            stock: {},
            displayType: 'stock',
            displayTypeTemp: 'stock',
            load: true,
            ...initialState
        }
        this.apply = this.apply.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    gen(data){
        generatePdf(data, 'stock')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            this.setState({popLoad: false})
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrint(){
        this.setState({popLoad: true})
        const store = Session.get('$sb_structure')
        const data = { stock: this.state.data, store }
        this.gen(data)
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.libelle.toLowerCase().includes(value.toLowerCase()) ||
                td?.description.toLowerCase().includes(value.toLowerCase()) ||
                td?.puvente.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.quantite.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.unite.toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    handleChange = (e, name) => this.setState({ stock: {...this.state.stock, [name]: e.target.value }})
    
    apply = (e) => {
        e.preventDefault();
        const { displayTypeTemp } = this.state
        this.setState({ popLoad: true })
        this.fetch(displayTypeTemp)
    }
    onEdit = (id) =>{
        const stock = this.state.data.find(s=>s.id === id)
        if(stock)this.setState({ stock: {...stock, expire: formatEditDate(stock.expire)}, modal: true })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleSubmit(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { stock } = this.state
        if(stock.id){
            this.props.controller.updateStock(stock.id, stock)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    fetchStores = () =>{
        this.props.storeController.getStores('produit')
        .then(data => {
            if (!data.error && typeof (data.pror) === 'undefined') this.setState({ storeOptions: data })
            else toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite.")
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    fetch = (type) => {
        this.props.controller.stock(type)
        .then(data => {
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false, displayType: type, displayTypeTemp: type, ...initialState }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false, ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false, ...initialState }) })
    }

    componentDidMount = () => {
        this.fetch(this.state.displayType)
        this.fetchStores()
    }

    render(){
        const { name } = this.props
        const { data, load, displayType, displayTypeTemp, modalDisplay, popLoad, modal, stock, storeOptions } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0" style={{display: 'flex'}}>
                                <li><button onClick={()=>this.setState({ modalDisplay: true })} className="btn btn-link"><i className="ft-filter"></i></button></li>
                                <li><button onClick={()=>this.handlePrint()} className="btn btn-link"><i className="ft-printer"></i></button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}
                            <div className="table-responsive">
                                <Table 
                                    columns={displayType === 'stock' ? columns : columnsAll}
                                    data={data} 
                                    load={load} 
                                    crud={displayType === 'stock' ? false : true}
                                    detaillable={false}
                                    deletable={false}
                                    printable={false}
                                    onEdit={(id)=> this.onEdit(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={modalDisplay} ariaHideApp={false}>
                    <h5>Charger l'affichage du tableau</h5>
                    <form  method="POST" onSubmit={this.apply}>
                        <span className="card-text">
                            <select className="form-control form-control-sm" value={displayTypeTemp} onChange={(e) => this.setState({displayTypeTemp: e.target.value})} required >
                                <option value="">--Type d'affichage--</option>
                                <option value="stock">Affichage par default</option>
                                <option value="stock-all">Affichage en détail</option>
                            </select>
                        </span>

                        <div style={{ textAlign: 'end', marginTop: 16 }}>
                            <button onClick={() => this.setState({ ...initialState, load: false })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type='submit' style={{ marginLeft: 8 }} className="btn btn-primary">Appliquer</button>
                        </div>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Modification du stock des produits finis</h5>

                    <form method="POST" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Libellé du produit fini</label>
                                    <input type="text" value={stock.libelle}  onChange={(e)=>this.handleChange(e, 'libelle')} className="form-control form-control-sm" readOnly />
                                </div>
                            </div>

                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Référence *</label>
                                    <input type="text" value={stock.reference}  onChange={(e)=>this.handleChange(e, 'reference')} className="form-control form-control-sm" required />
                                </div>
                            </div>
            
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Quantité achetée *</label>
                                    <input type="number" value={stock.quantite}  onChange={(e)=>this.handleChange(e, 'quantite')} className="form-control form-control-sm" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Quantité restante *</label>
                                    <input type="number" max={stock.quantite} value={stock.quantiteRestante}  onChange={(e)=>this.handleChange(e, 'quantiteRestante')} className="form-control form-control-sm" required />
                                </div>
                            </div>

                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Date de péremption *</label>
                                    <input type="date" value={stock.expire}  onChange={(e)=>this.handleChange(e, 'expire')} className="form-control form-control-sm" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Magasin de stockage *</label>
                                    <div style={{display: 'flex'}}>
                                        <select className="form-control form-control-sm" value={stock.storeId} onChange={(e) => this.handleChange(e, 'storeId')} required >
                                            <option value="">--Magasin de stockage--</option>
                                                {storeOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{textAlign: 'end' }}>
                            <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{marginLeft: 8}} className="btn btn-primary">Modifier</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>

            </Head>
        )
    }
}