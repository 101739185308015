const Footer = () =>{
  return (
    <footer className="footer footer-static footer-light navbar-border navbar-shadow" style={{position: 'fixed', width: '100%', bottom: 0}}>
        <div className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
            <span className="float-md-left d-block d-md-inline-block">
                2021  &copy; Copyright  
                <a className="text-bold-800 grey darken-2" style={{ color: "#b53da2 !important", marginLeft: 8 }} rel="noreferrer" href="https://codesign.ml" target="_blank">
                     CODESIGN
                </a>
            </span>
        </div>
    </footer>
  );
}

export default Footer;
