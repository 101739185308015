const SearchInput = ({ onChange, value })=>{
    return(
        <div className='row form-group'>
            <div className='col-ms-12 col-md-6 col-lg-4 nx-table-search'>
                <div className="position-relative has-icon-left">
                    <input type="text" className="form-control" placeholder="Rechercher dans le tableau" value={value} onChange={(e) => onChange(e.target.value)} />
                    <div className="form-control-position">
                        <i className="la la-search"></i>
                    </div>
                </div>
                {/* <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Rechercher dans le tableau" value={value} onChange={(e) => onChange(e.target.value)} />
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className="fas fa-search"></span>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}


export default SearchInput