import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../../components/Spinner'
import Head from '../../../components/Head'
import { Link } from 'react-router-dom'
import Session from '../../../session'
import { generatePdf } from '../../print.controller'
import Table from '../../../components/Table'
import SearchInput from '../../../components/Search'
import { formatDate } from '../../../lib/functions'

const columns = [
    { name: 'id', header: 'ID', prefix: 'CMD_' },
    { name: 'libelle', header: 'Libellé' },
    { name: 'transport', header: 'Transport', suffix: 'F cfa' },
    { name: 'stockage', header: 'Stockage', suffix: 'F cfa' },
    { name: 'charge', header: 'Autres charges', suffix: 'F cfa' },
    { name: 'total', header: 'Coût total', suffix: 'F cfa' },
    { name: 'paye', header: 'Payé', suffix: 'F cfa' },
    { name: 'reliquat', header: 'Reliquat', suffix: 'F cfa' },
    { name: 'etat', header: 'Etat' },
    { name: 'date', header: 'Date', date: 'date-fr' },
]

const initialState = { 
    modal: false, modalDelete: false, load: true, 
    libelle: '', description: '', currentId: ''
}
const initialPaiementState = { montantPaiement: 0, currentPaiementReliquat: 0, modalPaiement: false }

class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ...initialState,
            ...initialPaiementState,
            data: [],
            tempData: [],
        }
        this.handleDelete = this.handleDelete.bind(this)
        this.handlePaiementSubmitSave = this.handlePaiementSubmitSave.bind(this)
    }
    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleView(id){ this.props.history.push('commandes/'+id  ) }

    handleEditOpen(id){
        const current = this.state.data.find(d=> d.id === id)
        if(current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id){
        if(id) this.setState({ modalDelete: true, currentId: `${id}` })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handlePaiementOpen(id){
        const current = this.state.data.find(d=> d.id === id || d._id === id)
        if(current){
            if(parseInt(current.reliquat) > 0) this.setState({ modalPaiement: true, currentId: current.id, currentPaiementReliquat: current.reliquat })
            else toast.info("Cette commande a déjà été réglé !")
        }
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleDelete(){
        this.setState({ modal: true, modalDelete: false })
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.fetch()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) })
        }
    }

    handlePaiementSubmitSave(e){
        e.preventDefault();
        this.setState({ modal: true })
        const { currentId, montantPaiement } = this.state
        const data = { montant: montantPaiement, approvisionnement_id: currentId }

        this.props.controller.savePaiement(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialPaiementState, modal: false })
                toast.success(res.message)
                this.fetch()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({modal: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({modal: false}) })
    }

    gen(data){
        generatePdf(data, 'approvisionnements')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrintOne(id){
        this.setState({modal: true})
        const store = Session.get('$sb_structure')
        
        this.props.controller.details(id)
        .then(res=>{
            console.log(res)
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: false })
                this.gen({...res, store})
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }
    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.transport.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.stockage.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.charge.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.total.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.paye.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.reliquat.toString().toLowerCase().includes(value.toLowerCase()) ||
                formatDate(td?.date).toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    fetch = () =>{
        this.props.controller.commandes()
        .then(data => {
            if (!data.error && typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, load: false })
            } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }
    componentDidMount = () => this.fetch()
    render(){
        const { name } = this.props
        const { data, modal, modalDelete, load, montantPaiement, currentPaiementReliquat, modalPaiement } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a> */}
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0">
                                {/* <li><Link to="#"><i className="ft-printer"></i></Link></li> */}
                                <li><Link to={`commandes-new`}><i className="ft-plus"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}   
                            <div className="table-responsive">
                            <Table columns={columns} data={data} load={load} editable={false} countable={false}
                                hasCustomItem={true}
                                customItemIcon= 'la la-money'
                                customItemLabel='Paiement'
                                onCustomItemAction={(id)=>this.handlePaiementOpen(id)}
                                onView={(id) => this.handleView(id)}
                                onPrint={(id) => this.handlePrintOne(id)}
                                onDelete={(id) => this.handleDeleteOpen(id)}
                            />
                            </div>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={modalPaiement} ariaHideApp={false}>
                    <h5>Nouveau paiement</h5>

                    <form method="POST" onSubmit={this.handlePaiementSubmitSave}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className='nx-input-label'>Montant *</label>
                                    <input type="number" min={5} value={montantPaiement}  onChange={(e)=>this.handleChange(e, 'montantPaiement')} className="form-control" placeholder="Montant *" required />
                                </div>
                                <p>Reliquat: {currentPaiementReliquat} Fcfa</p>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                            {montantPaiement <= currentPaiementReliquat && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>}
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>



                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion de la commande</h5>
                    <span className="card-text">
                        Etes-vous sûr de vouloir supprimer cette commande ? <br/>
                        Toutes les matières premières issues de cette commande seront supprimées.  <br/>
                        Attention, cette action est irreversible!
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialState, load: false })} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                    </div>
                </ReactModal>
            </Head>
        )
    }
}

export default Home;