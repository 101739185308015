export default {

    login: (user) =>{
       const store =  {
          id: user['id'],
          name: user['nom'],
          username: user['login'],
          role: user['libelle'],
          permissions: user['permissions'],
          password: user['password'],
       }
       
       localStorage.setItem('$sb_user', JSON.stringify(store))
    },

    structure: (str) =>{
      const store =  {
         nom: str['nom'],
         email: str['email'],
         telephone: str['telephone'],
         logo: str['logo'],
         logoPath: str['logoPath'],
         adresse: str['adresse'],
      }
      
      localStorage.setItem('$sb_structure', JSON.stringify(store))
   },

    config: (org) =>{
         const store =  {
           id: org['id'],
           name: org['dbname'],
           productkey: org['productkey'],
           debut: org['debut'],
           fin: org['fin'],
         }
        localStorage.setItem('$sb_org', JSON.stringify(store))
        localStorage.setItem('$sb_baseUrl', org['_id'])
     },

    store: ()=>{
      const session = {
         user: JSON.parse(localStorage.getItem('$sb_user')),
         org: JSON.parse(localStorage.getItem('$sb_org')),
         baseUrl: localStorage.getItem('$sb_baseUrl')
      }
      return session
    },

   get: (item) => JSON.parse(localStorage.getItem(item)),
   getString: (item) => localStorage.getItem(item),
   remove: (item) => localStorage.removeItem(item),
   put: (item, value) => localStorage.setItem(item, value),
   reset: () => localStorage.clear(),
}