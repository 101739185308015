import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { Link } from 'react-router-dom'
import Accordion from '../../components/Accordion'

const initialState = {
    modal: false, modalDelete: false, action: '', popLoad: false,
    date: '', montant: '', motif: '', currentId: ''
}

export default class Depense extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            data: [],
            dataTemp: [],
            load: true,
            currentYear: ''
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }
    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const current = this.state.dataTemp.find(d => d.id === id)
        if (current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id) {
        if (id) this.setState({ modalDelete: true, currentId: id })
        else toast.error("Une erreur inattendue s'est produite.")
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { date, montant, motif } = this.state
        const data = { date, montant, motif }

        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: true })
                toast.success(res.message)
                this.componentDidMount()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, date, montant, motif } = this.state
        const data = {  id: currentId.toString(), date, montant, motif }
        if(currentId){
            this.props.controller.save(data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.componentDidMount()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handleDelete(){
        this.setState({popLoad: true})
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) })
        }
    }

    componentDidMount() {
        const currentYear = new Date().getFullYear()
        this.fetch(currentYear)
    }

    fetch = (year)=>{
        this.setState({ btnLoad: true, currentYear: year })
        this.props.controller.ByYear(`${year}`)
        .then(data => {
            if (!data.error && typeof (data.pror) === 'undefined') {
                const grp = this.groupBy(data, 'month')
                this.setState({ data: grp, dataTemp: data, load: false, btnLoad: false })
            } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false, btnLoad: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false, btnLoad: false }) })
    }

    groupBy = (array, key)=> {
        return array.reduce((rv, x)=> {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    }

    fomateName = (str) =>{
        const st = str.split('-')
        const months = ['-', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
        const month = months[st[0]]
        return month ? `${month}  ${st[1]}` : `-  ${st[1]}`
    }

    render() {
        const { name } = this.props
        const { data, modal, action, modalDelete, load, popLoad, date, montant, motif, currentYear, btnLoad } = this.state
        return (
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0">
                                <li><Link to="#" onClick={() => this.setState({ modal: true, action: 'save' })}><i className="ft-plus"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className='nx-collapse-actions-container'>
                            <div className='nx-collapse-input-container'>
                                <label className='nx-input-label'>Filter par année</label>
                                <select className='form-control form-control-sm' style={{height: 26}} value={currentYear} onChange={(e)=>this.setState({ currentYear: e.target.value })}>
                                    <option value='2021'>2021</option>
                                    <option value='2022'>2022</option>
                                    <option value='2023'>2023</option>
                                </select>
                            </div>
                            <button ctype="button" disabled={btnLoad} onClick={()=>this.fetch(currentYear)} className='btn nx-collapse-actions-btn' style={{background: '#000', color: '#fff'}}>
                                {!btnLoad && 'Filter'}
                                {btnLoad && <Spinner color='#fff' size={16} weight='1px' />}
                            </button>
                        </div>
                        <div className="card-body">
                            {load && <Spinner />}
                            <div className="table-responsive">
                                {/* <Table columns={columns} data={data} load={load} detaillable={false} printable={false}
                                    onEdit={(id) => this.handleEditOpen(id)}
                                    onDelete={(id) => this.handleDeleteOpen(id)}
                                /> */}
                                {Object.keys(data).map((month,index)=>{
                                    return(
                                        <Accordion title={this.fomateName(month)} key={index}>
                                            <table style={{marginBottom: 0}} className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Montant</th>
                                                        <th>Motif</th>
                                                        <th>Date</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data[month].length > 0 &&
                                                    data[month].map((d,i)=>{
                                                        return(
                                                            <tr key={i}>
                                                                <td>{`CHF_${d.id}`}</td>
                                                                <td>{d.montant}</td>
                                                                <td>{d.motif}</td>
                                                                <td>{d.date}</td>
                                                                <td>
                                                                    <button className="btn btn-link" onClick={()=>this.handleEditOpen(d.id)}>
                                                                        <i className="la la-pencil"></i>
                                                                    </button>
                                                                    <button className="btn btn-link" onClick={()=>this.handleDeleteOpen(d.id)}>
                                                                        <i className="la la-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })

                                                    }
                                                </tbody>
                                            </table>
                                            {typeof(data[month].length) === 'undefined' && !load &&
                                            <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center', marginTop: 8}}>
                                                {`Aucune information trouvée !`}
                                            </span>}
                                        </Accordion>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>{action === 'save' ? "Ajout d'une nouvelle charge fixe" : "Modification de la charge fixe"}</h5>

                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate}>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-input-label'>Date *</label>
                                <div className="form-group">
                                    <input type="date" value={date} onChange={(e) => this.handleChange(e, 'date')} className="form-control form-control-sm" placeholder="Date" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-input-label'>Montant *</label>
                                <div className="form-group">
                                    <input type="number" min="5" value={montant} onChange={(e) => this.handleChange(e, 'montant')} className="form-control form-control-sm" placeholder="Montant" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12">
                                <label className='nx-input-label'>Motif *</label>
                                <div className="form-group">
                                    <input type="text" value={motif} onChange={(e) => this.handleChange(e, 'motif')} className="form-control form-control-sm" placeholder="Motif" required />
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion de la charge fixe</h5>

                    <span className="card-text">
                        {`Etes-vous sûr de vouloir supprimer cette charge fixe, cette action est irreversible et entrainera la suppression de la charge fixe.`}
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>

                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>

                </ReactModal>
            </Head>
        )
    }
}