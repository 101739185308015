import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import md5 from 'md5'
import Session from '../../session'
import Spinner from '../../components/Spinner'
import logo from '../../assets/img/logo.png'

const initialState = { password: '', login: '' }
export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = { load: false, ...initialState }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleSubmit = e => {
        e.preventDefault()
        this.setState({ load: true })
        const { login, password } = this.state
        const data = { login, password: md5(password) }

        this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    if (res.id) {
                        Session.login(res)
                        const dash = JSON.parse(res.permissions)['dashboard']
                        if (dash === '1') window.location = '/home'
                        else window.location = '/profil'
                    } else {
                        toast.error("Une erreur inattendue s'est produit.")
                        this.setState({ load: false })
                    }
                } else {
                    // Session.reset()
                    this.setState({ load: false })
                    toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produit.")
                }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })

    }

    goToWorkspace() {
        Session.reset()
        window.location = '/'
    }

    render() {
        const { load } = this.state
        return (
            <div className="app-content">
                <div className="content-wrapper">
                    <ToastContainer />
                    <div className='row' style={{ marginLeft: 0, marginRight: 0, height: '100vh', marginTop: '-5rem' }}>
                        <div className="col-ms-12 col-lg-5 ">
                            <div className='nx-login-form-container'>
                                <div className="nx-login-form-content card-body login-card-body">
                                    <div className="login-logo">
                                        <img src={logo} alt="logo" width="150" />
                                    </div>

                                    <h1 className='nx-login-welcome'>BIENVENUE SUR L'ESPACE SANI-BARA</h1>
                                    <div className='nx-login-msg-container'>
                                        <div className='nx-mi-divier'></div>
                                        <span style={{ marginLeft: 8, marginRight: 8 }}>CONNEXION À VOTRE COMPTE</span>
                                        <div className='nx-mi-divier'></div>
                                    </div>

                                    <form onSubmit={this.handleSubmit}>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'login')} placeholder="Nom d'utilisateur" required />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            <input type="password" className="form-control" onChange={(e) => this.handleChange(e, 'password')} placeholder="Mot de passe" required />
                                        </fieldset>
                                        <fieldset className="form-group">
                                            {load && <Spinner />}
                                            {!load && <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>Se connecter</button>}
                                        </fieldset>

                                        <div className="row" style={{marginTop: 12}}>
                                            <div className="col-lg-6 col-ms-12">
                                                <button type="button" className="btn btn-link btn-block" style={{textAlign: 'left'}} onClick={()=>this.goToWorkspace()} >Changer l'espace de travail</button>
                                            </div>
                                            <div className="col-lg-6 col-ms-12">
                                                <button type="button" className="btn btn-link btn-block" style={{textAlign: 'left'}} >Mot de passe oublier ?</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-ms-12 col-lg-7 nx-login-description-content" >
                            <div className='nx-login-description-wraper'>
                                <button className='btn nx-login-policy-btn'>
                                    <span className="la la-book" style={{ marginRight: 8 }}></span>Politique de confidentialité
                                </button>
                                <p>
                                    Renseigner vos informations d'identification pour acceder à l'application.
                                </p>
                            </div>
                            <div className='nx-login-description'></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}