import React from "react";
import { Link } from "react-router-dom";

export default class Head extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        const { breadcrumb, name, subname, hasub, expired } = this.props
        return (
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-6 col-12 mb-2">
                            <h3 className="content-header-title">{hasub ? subname : name}</h3>
                        </div>
                        <div className="content-header-right col-md-6 col-12">
                            <div className="breadcrumbs-top float-md-right">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb">
                                        {!expired &&
                                        <li className="breadcrumb-item">
                                            <Link to="/home">Accueil</Link>
                                        </li>}
                                        {hasub &&
                                            <li className="breadcrumb-item">
                                                <Link to={breadcrumb}>{name}</Link>
                                            </li>
                                        }
                                        {!hasub && <li className="breadcrumb-item active">{name}</li> }
                                        {hasub && <li className="breadcrumb-item active">{subname}</li> }
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-12">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}

Head.defaultProps = {
    hasub: false,
    expired: false
}

  