import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import md5 from 'md5'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { Link } from 'react-router-dom'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const columns = [
    { name: 'nom', header: 'Nom complet'},
    { name: 'login', header: 'Identifiant'},
    { name: 'libelle', header: 'Rôle' }
]

const initialState = {
    login: '',
    nom: '',
    role_id: '',
    password: '',
    confirmation: '',
    action: '',
    currentId: '',
    currentStatut: '',
    // for edit
    editRoleLibelle: '',
    editRoleId: '',
    //===========
    popLoad: false,
    modal: false,
    modalDelete: false
}

export default class Utilisateur extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            roleOptions: [],
            load: true,
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const model = this.state.data.find(d => d.id === id)
        if (model) this.setState({ ...model, editRoleId: model.role_id, editRoleLibelle: model.libelle, modal: true, action: 'update', currentId: id })
    }
    handleDeleteOpen(id, statut) {
        if (id) { this.setState({ modalDelete: true, currentId: id, currentStatut: statut }) }
        else { toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { nom, login, role_id, password, confirmation } = this.state
        if (password !== '' && password === confirmation) {
            const data = { nom, login, role_id, password: md5(password), role: 'user', statut: 1 }
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.fetch()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        } else { toast.error("Mot de passe et Confirmation ne correspondent pas."); this.setState({ popLoad: false }) }

    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, nom, role_id, password, confirmation } = this.state
        const data = { id: currentId.toString(), nom, role_id, password: md5(password) }
        if (currentId) {
            if (password !== '' && password === confirmation) {
                this.props.controller.save(data)
                    .then(res => {
                        if (!res.error && typeof (res.pror) === 'undefined') {
                            this.setState({ ...initialState })
                            toast.success(res.message)
                            this.fetch()
                        }
                        else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                    })
                    .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
            } else { toast.error("Mot de passe et Confirmation ne correspondent pas."); this.setState({ popLoad: false }) }
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId, currentStatut } = this.state
        if (currentId !== '' && currentStatut !== '') {
            this.props.controller.remove(`${currentId}`)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.fetch()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
        }
    }

    getRoleList() {
        this.props.roleController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') this.setState({ roleOptions: res })
                else toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.")
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.login.toLowerCase().includes(value.toLowerCase()) ||
                td?.libelle.toLowerCase().includes(value.toLowerCase()) 
            )
        })
        this.setState({ data, search: value })
    }

    fetch = () =>{
        this.props.controller.fetch()
        .then(data => {
            if (!data.error && typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, load: false })
            } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    componentDidMount = ()=> {
        this.fetch()
        this.getRoleList()
    }

    render() {
        const { name } = this.props
        const { data, roleOptions, modal, modalDelete, nom, login, editRoleLibelle, editRoleId, currentStatut, action, load, popLoad } = this.state
        return (
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0">
                                <li><Link to="#" onClick={() => this.setState({ modal: true, action: 'save' })}><i className="ft-plus"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}
                            <div className="table-responsive">
                                <Table columns={columns} data={data} load={load} detaillable={false} printable={false}
                                    userTable={true} adminAtt='libelle' adminVal='Administrateur'
                                    onEdit={(id) => this.handleEditOpen(id)}
                                    onDelete={(id) => this.handleDeleteOpen(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>{action === 'save' ? "Ajout d'un nouvel utilisateur" : "Modification de l'utilisateur"}</h5>
                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate}>
                        <div className="row">
                            <div className={action === 'save' ? "col-sm-12 col-lg-12" : "col-sm-12 col-lg-6"}>
                                <div className="form-group">
                                    <label className='nx-input-label'>Nom complet *</label>
                                    <input type="text" value={nom} onChange={(e) => this.handleChange(e, 'nom')} className="form-control form-control-sm" placeholder="Nom complet *" required />
                                </div>
                            </div>
                            {action === 'save' &&
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label className='nx-input-label'>Identifiant *</label>
                                        <input type="text" value={login} onChange={(e) => this.handleChange(e, 'login')} className="form-control form-control-sm" placeholder="Identifiant *" required />
                                    </div>
                                </div>}
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Rôle *</label>
                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'role_id')} required >
                                        {action === 'save' && <option value="">--Rôle--</option>}
                                        {action !== 'save' && <option value={editRoleId}>{editRoleLibelle}</option>}
                                        {roleOptions.length > 0 &&
                                            roleOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Mot de passe *</label>
                                    <input className="form-control form-control-sm" type="password" onChange={(e) => this.handleChange(e, 'password')} placeholder="Mot de passe *" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Confirmation *</label>
                                    <input className="form-control form-control-sm" type="password" onChange={(e) => this.handleChange(e, 'confirmation')} placeholder="Confirmation *" required />
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                        </div>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion de l'utilisateur</h5>

                    <span className="card-text">
                        {`Etes-vous sûr de vouloir supprimer cet utilisateur, cette action est irreversible.`}
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">{currentStatut == 1 ? "Désactiver" : "Activer"}</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>
            </Head>
        )
    }
}