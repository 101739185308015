import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import { formatDate, formatNumber } from '../../lib/functions'

const paragraphe = {background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {marginBottom: 16, border: '1px solid #ddd', paddingTop: 24, paddingBottom: 24, borderRadius: 8, top: -24, position: 'relative'}

export default class Detail extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            popLoad: true,
            data: [],
            production: {},
        }
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = (sum + (data[i]['pu'] * data[i]['quantite']) ) }
        return sum
    }

    gen(data){
        generatePdf(data, 'productions')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            this.setState({popLoad: false})
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrint(){
        this.setState({popLoad: true})
        const store = Session.get('$sb_structure')
        this.gen({production: this.state.production, details: this.state.data, store})
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.controller.details(id)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                const totalSansTva = this.calculMontant(res.details)
                this.setState({ production: res.production, data: res.details, totalSansTva, popLoad: false })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    render(){
        const { name } = this.props
        const { data, production, popLoad  } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{padding: 0, display: 'inline-block'}} onClick={()=>this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{display: 'inline-block'}}>Détail de la production</h4>
                        <div style={{float: 'right'}}>
                            <button className="btn btn-link" style={{padding: 0}} onClick={()=>this.handlePrint()}>
                                <i className="la la-print"></i>
                            </button>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body" style={{marginLeft: 14, marginRight: 14}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text" style={paragraphe}>Les informations concernant la production. </p>
                                    <div className="row" style={{...row, marginRight: 8}}>
                                        <div className="col-sm-12">
                                            <p style={{marginBottom: 4}}><strong>DATE DE LA PRODUCTION : </strong>{formatDate(production.date)}</p>
                                            <p style={{marginBottom: 4}}><strong>DESCRIPTION : </strong>{production.description}</p>
                                            <p style={{marginBottom: 4}}><strong>MAIN D'OEUVRE : </strong>{formatNumber(production.mainOeuvre)} F CFA</p>
                                            <p style={{marginBottom: 4}}><strong>AUTRES CHARGES : </strong>{formatNumber(production.charge)} F CFA</p>
                                            <p style={{marginBottom: 4}}><strong>MOTIF AUTRES CHARGES : </strong>{production.motif}</p>
                                            <p style={{marginBottom: 4}}><strong>COÛT DE LA PRODUCTION : </strong>{formatNumber(production.total)} F CFA</p>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative', top: -24}}>
                                        <p className="card-text" style={paragraphe}>Bénéfices prévisionnels.</p>
                                        <div className="row" style={{...row, marginRight: 8}}>
                                            <div className="col-12">
                                                <p style={{marginBottom: 4}}><strong>EN GROS : </strong>{formatNumber((production.puGros*production.quantite) - production.total)} F CFA</p>
                                                <p style={{marginBottom: 4}}><strong>DETAIL : </strong>{formatNumber((production.puDetail*production.quantite) - production.total)} F CFA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <p className="card-text" style={paragraphe}>Information du produit fini.</p>
                                    <div className="row" style={row}>
                                        <div className="col-12">
                                        <p style={{marginBottom: 4}}><strong>LIBELLÉ : </strong>{production.produit}</p>
                                        <p style={{marginBottom: 4}}><strong>RÉFÉRENCE : </strong>{production.reference}</p>
                                            <p style={{marginBottom: 4}}><strong>P.U EN GROS : </strong>{formatNumber(production.puGros)} F CFA</p>
                                            <p style={{marginBottom: 4}}><strong>P.U DETAIL  : </strong>{formatNumber(production.puDetail)} F CFA</p>
                                            <p style={{marginBottom: 4}}><strong>UNITÉ DE MÉSURE : </strong>{production.unite}</p>
                                            <p style={{marginBottom: 4}}><strong>QUANTITÉ PRODUITE : </strong>{formatNumber(production.quantite)}</p>
                                            <p style={{marginBottom: 4}}><strong>QUANTITÉ VENDUE : </strong>{formatNumber(production.quantite - production.quantiteRestante)}</p>
                                            <p style={{marginBottom: 4}}><strong>DATE DE PÉREMPTION : </strong>{formatDate(production.expire)}</p>
                                            <p style={{marginBottom: 4}}><strong>DESCRIPTION : </strong>{production.descriptionProduit}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table" id="datatable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Matières premières</th>
                                            <th>P.U d'achat</th>
                                            <th>Quantité</th>
                                            <th>Montant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 &&
                                        data.map((d, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{d.matiere}</td>
                                                    <td>{formatNumber(d.pu)} F CFA</td>
                                                    <td>{`${d.quantite} (${d.unite})`}</td>
                                                    <td>{`${formatNumber(d.pu * d.quantite)} F CFA`}</td>
                                                    
                                                </tr>
                                            )
                                        }) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Chargement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, les données sont en cours de chargement ...`}
                    </span>
                </ReactModal>
            </Head>
        )
    }
}