import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import md5 from 'md5'
import Head from '../../components/Head'
import Session from '../../session'

const initialState = { actuel: '', nouveau: '', confirmation: '', popLoad: false}

export default class Profil extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            user: Session.get('$sb_user'),
            ...initialState
        }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleSubmitUpdate(e){
        e.preventDefault()
        this.setState({popLoad: true})
        const { actuel, nouveau, confirmation, user } = this.state
        if(nouveau !== '' && nouveau === confirmation){
            if(md5(actuel) === user.password){
                if(user.id){
                    this.props.controller.updatePassword(user.id.toString(), { password: md5(nouveau)})
                    .then(res=>{
                        if(!res.error && typeof(res.pror) === 'undefined'){
                            this.setState({ ...initialState })
                            toast.success(res.message)
                        }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
                    })
                    .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
                }else{ toast.error("Une erreur inatendue s'est produite."); this.setState({popLoad: false}) }
            }else{  toast.error("L'actuel mot de passe n'est pas valide."); this.setState({popLoad: false}) }
        }else{ toast.error("Mot de passe et confirmation ne correspondent pas."); this.setState({popLoad: false}) }
    } 

    render(){
        const { name } = this.props
        const { user, popLoad, actuel, nouveau, confirmation } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"> <h4 className="card-title">Mes informations</h4> </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="card-text" style={{display: 'flex', justifyContent: 'space-around', gap: 12, flexWrap: 'wrap'}}>
                                        <div>
                                            <h5>Nom</h5>
                                            <p>{user?.name}</p>
                                        </div>
                                        <div>
                                            <h5>Nom d'utilisateur</h5>
                                            <p>{user?.username}</p>
                                        </div>
                                        <div>
                                            <h5>Rôle</h5>
                                            <p>{user?.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"> <h4 className="card-title">Modifier le mot de passe</h4> </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <form method="POST" onSubmit={this.handleSubmitUpdate}>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input onChange={(e)=>this.handleChange(e, 'actuel')} value={actuel} type="password" className="form-control form-control-sm" placeholder="Mot de passe actuel" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input onChange={(e)=>this.handleChange(e, 'nouveau')} value={nouveau} type="password" className="form-control form-control-sm" placeholder="Nouveau *" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input onChange={(e)=>this.handleChange(e, 'confirmation')} value={confirmation} className="form-control form-control-sm" type="password" placeholder="Confirmation *" required />
                                                </div>
                                            </div>
                                        </div>

                                        <button type="submit" name="valider" className="btn btn-primary">Modifier</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>
            </Head>
        )
    }
}