import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../../components/Spinner'
import Head from '../../../components/Head'
import { Link } from 'react-router-dom'

const initialState = {
    matiere_id: '', store_id: '', storeNom: '', expire: '', reference: '',
    //====================Matiere premiere ====================
    pu: '', quantite: '', matiereDescription: '', matiereUnite: '', matiereLibelle: '',
}

const initialStateModal = {  popLoad: false, modal: false, modalDelete: false }


const paragraphe = {background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {marginBottom: 16, border: '1px solid #ddd', paddingTop: 24, paddingBottom: 24, borderRadius: 8, top: -24, position: 'relative'}

export default class New extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ...initialState,
            ...initialStateModal,
            data: [],
            matiereOptions: [],
            storeOptions: [],
            montantPaye: 0,
           
            //====================Production===========================
            libelle: '', date: '', autreCharge: '', motifAutreCharge: '', transport: '', stockage: '',
           
            total: 0,
            tempTotal: 0,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = (sum + (data[i]['pu'] * data[i]['quantite'])) }
        return sum
    }
    
    handleChange(e, name) {
        if (name === 'matiere_id') {
            const matiere = this.state.matiereOptions.find(a => a.id == e.target.value)
            if (typeof (matiere) !== 'undefined') {
                this.setState({ [name]: e.target.value, matiereDescription: matiere.description, matiereUnite: matiere.unite, matiereLibelle: matiere.libelle })
            }
        } else {
            if(name === 'produit_id'){
                const produit = this.state.produitOptions.find(a => a.id == e.target.value)
                if (typeof (produit) !== 'undefined') { this.setState({ [name]: e.target.value, produitSelected: produit, prd: true }) }
            }else{
                if(name === 'store_id'){
                    const store = this.state.storeOptions.find(a => a.id == e.target.value)
                    if (typeof (store) !== 'undefined') { this.setState({ [name]: e.target.value, storeNom: store.nom }) }
                }else{
                    if(name === 'stockage'){
                        const total = 
                        parseFloat(this.state.tempTotal) + 
                        parseFloat(e.target.value === '' ? '0' : e.target.value) + 
                        parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge) +
                        parseFloat(this.state.transport === '' ? '0' : this.state.transport)
                        this.setState({ [name]: e.target.value, total })
                    }else{
                        if(name === 'transport'){
                            const total = 
                            parseFloat(this.state.tempTotal) + 
                            parseFloat(e.target.value === '' ? '0' : e.target.value) + 
                            parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge) +
                            parseFloat(this.state.stockage === '' ? '0' : this.state.stockage)
                            this.setState({ [name]: e.target.value, total })
                        }else{
                            if(name === 'autreCharge'){
                                const total = 
                                parseFloat(this.state.tempTotal) + 
                                parseFloat(e.target.value === '' ? '0' : e.target.value) + 
                                parseFloat(this.state.transport === '' ? '0' : this.state.transport) +
                                parseFloat(this.state.stockage === '' ? '0' : this.state.stockage)
                                this.setState({ [name]: e.target.value, total })
                            }else{ 
                                this.setState({ [name]: e.target.value }) 
                            }
                        }
                    }
                }
            }
        }
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        const { 
            matiere_id, quantite, pu, store_id, reference, expire, 
            matiereDescription, matiereUnite, matiereLibelle, storeNom,
            data, stockage, transport, autreCharge
        } = this.state
        const matiere = { 
            id: data.length +1, 
            matiere_id, 
            quantite: parseFloat(quantite),
            quantiteRestante: parseFloat(quantite),
            quantiteLivree: parseFloat(quantite),
            pu,
            store_id,
            storeNom,
            reference, 
            expire,
            matiereLibelle,
            matiereDescription,
            matiereUnite,
            type: 'Achat',
            etat: 'LIVREE'
        }
        const datas = [...data, matiere]
        const t = this.calculMontant(datas)
        const total = t + 
        parseFloat(autreCharge === '' ? '0' : autreCharge) + 
        parseFloat(transport === '' ? '0' : transport) +
        parseFloat(stockage === '' ? '0' : stockage)
        // parseFloat(this.state.total)
        this.setState({ data: datas, total, tempTotal: t, ...initialState })
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({ popLoad: true })
        const { libelle, date, stockage, transport, autreCharge, motifAutreCharge, total, data, montantPaye } = this.state
        if(data.length > 0){
            if(Number(montantPaye) <= Number(total)){
                const datas = { 
                    approvisionnement: { 
                        libelle,
                        date,
                        stockage,
                        transport,
                        charge: autreCharge ? autreCharge : 0,
                        motif: motifAutreCharge ? motifAutreCharge : 'Autre charge',
                        total,
                        type: 'Achat',
                        etat: 'LIVREE'
                    },
                    details: data, montantPaye: montantPaye ? montantPaye : 0
                }
    
                this.props.controller.save(datas)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        console.log(res)
                        this.setState({ ...initialState, ...initialStateModal, data: [], montantPaye: 0, total: 0, tempTotal: 0, autreCharge: '', motifAutreCharge: '',
                        date: '', stockage: '', transport: '' })
                        toast.success(res.message)
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
            }else{ toast.error("Le montant avancé n'est pas être superieur à celui du côut d'approvisionnement."); this.setState({ popLoad: false }) }
        }else{ toast.error("La liste de produits à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }

    getMatiereList() {
        this.props.matiereController.fetch()
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                this.setState({ matiereOptions: res })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    getStoreList() {
        this.props.storeController.getStores('matiere')
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                this.setState({ storeOptions: res })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }


    componentDidMount = ()=> {
        this.getMatiereList()
        this.getStoreList()
    }

    render(){
        const { name } = this.props
        const { data,
            libelle, date, transport, stockage, autreCharge, motifAutreCharge,
            modal, matiereOptions, storeOptions, matiere_id, matiereUnite, matiereDescription, store_id, pu, quantite, expire, reference,
            total,
            montantPaye,
            popLoad  
        } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{padding: 0, display: 'inline-block'}} onClick={()=>this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{display: 'inline-block'}}>Approvisionnement du magasin en  matière première</h4>
                    </div>
                    <div className="card-content collapse show">
                        <form method="POST" onSubmit={this.handleSubmitSave}>
                            <div className="card-body" style={{marginLeft: 14, marginRight: 14}}>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="card-text" style={paragraphe}>Renseigner les informatios concernant l'approvisionnement. </p>
                                        <div className="row" style={row}>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Libelle *</label>
                                                <input type="text" value={libelle} onChange={(e) => this.handleChange(e, 'libelle')} className="form-control form-control-sm" placeholder="Libellé" required />
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Date *</label>
                                                <input type="date" value={date} onChange={(e) => this.handleChange(e, 'date')} className="form-control form-control-sm" placeholder="Date" required />
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Coût de stockage *</label>
                                                <input type="number" value={stockage} min="0" onChange={(e) => this.handleChange(e, 'stockage')} className="form-control form-control-sm" placeholder="Coût de stockage" required />
                                            </div>
                                            <div style={{width: '100%', height: 16}}></div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Coût de transport *</label>
                                                <input type="number" value={transport} min="0" onChange={(e) => this.handleChange(e, 'transport')} className="form-control form-control-sm" placeholder="Coût de transport" required />
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Autres charges</label>
                                                <input type="number" value={autreCharge} onChange={(e) => this.handleChange(e, 'autreCharge')} className="form-control form-control-sm" placeholder="Autres charges" />
                                            </div>
                                            <div className="col-sm-12 col-lg-4">
                                                <label className='nx-input-label'>Motif autres charges</label>
                                                <input type="text" value={motifAutreCharge} onChange={(e) => this.handleChange(e, 'motifAutreCharge')} className="form-control form-control-sm" placeholder="Motif autres charges" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Information sur le coût d'approvisionnement.</p>
                                        <div className="row" style={{...row, marginRight: 8}}>
                                            <div className="col-12">
                                                <label className='nx-label'>Coût d'approvisionnement : <strong>{`${total} Fcfa`}</strong></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Paiement des coûts d'approvisionnement.</p>
                                        <div className="row" style={row}>
                                            <div className="col-12">
                                                <input type="number" value={montantPaye} onChange={(e) => this.handleChange(e, 'montantPaye')} className="form-control form-control-sm" placeholder="Montant" />
                                                <label className='nx-label' style={{marginTop: 12, marginBottom: 0}}>Reste à payer (Reliquat) : <strong>{`${total - montantPaye} Fcfa`}</strong></label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="table-responsive">
                                    <table className="table" id="datatable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Réference</th>
                                                <th>Libellé</th>
                                                <th>Expire le</th>
                                                <th>P.U d'achat</th>
                                                <th>Qté</th>
                                                <th>Magasin</th>
                                                <th>Montant</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length > 0 &&
                                            data.map((d, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{d.reference}</td>
                                                        <td>{d.matiereLibelle}</td>
                                                        <td>{d.expire}</td>
                                                        <td>{d.pu}</td>
                                                        <td>{`${d.quantite} (${d.matiereUnite})`}</td>
                                                        <td>{d.storeNom}</td>
                                                        <td>{(d.pu * d.quantite)}</td>
                                                        <td>
                                                            <button className="btn btn-link" onClick={()=>{
                                                                const datas = data.filter(f=>f.id !== d.id)
                                                                const t = this.calculMontant(datas) 
                                                                const total = t + 
                                                                parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge) + 
                                                                parseFloat(this.state.transport === '' ? '0' : this.state.transport) +
                                                                parseFloat(this.state.stockage === '' ? '0' : this.state.stockage)

                                                                this.setState({ data: datas, total, tempTotal: t, ...initialState })
                                                            }}>
                                                                <i className="la la-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) }
                                        </tbody>
                                    </table>
                                    <Link to="#" className="btn btn-default" onClick={()=>this.setState({ modal: true })}>Ajouter une matiere première</Link>
                                </div>
                            </div>
                            <div style={{ textAlign: 'end', paddingRight: 16, paddingBottom: 16 }}>
                                <button onClick={()=>this.props.history.goBack()} type="button" className="btn btn-secondary" style={{color: '#fff'}}>Annuler</button>
                                <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Nouvelles matières premières</h5>

                    <form method="POST" onSubmit={this.handleSubmitAddElement}>

                        <p className="card-text">Renseigner la Matière première achetée. </p>
                        <div className="row" style={{marginBottom: 12}} >
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Matière première *</label>
                                <select className="custom-select form-control-sm" onChange={(e) => this.handleChange(e, 'matiere_id')} value={matiere_id} required>
                                    <option selected value="">--Matière première--</option>
                                    {matiereOptions.length > 0 &&
                                        matiereOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                    }
                                </select>
                                {matiereDescription !== '' && <p style={{fontSize: 11, marginBottom: 0, marginTop: 4}}><strong>Description:</strong> {matiereDescription}</p>}
                                {matiereUnite !== '' && <p style={{fontSize: 11, marginBottom: 0, marginTop: 4}}><strong>Unité:</strong> {matiereUnite}</p>}
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Quantité *</label>
                                <input type="number" className="form-control form-control-sm" min="0.0001" step="0.0001" value={quantite} onChange={(e) => this.handleChange(e, 'quantite')} placeholder="Quantité" required />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: 12}}>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Prix unitaire d'achat *</label>
                                <input type="number" className="form-control form-control-sm" value={pu} min="5" onChange={(e) => this.handleChange(e, 'pu')} placeholder="Prix unitaire d'achat"  required />
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Magasin de stockage *</label>
                                <select className="custom-select form-control-sm" onChange={(e) => this.handleChange(e, 'store_id')} value={store_id} required>
                                    <option selected value="">--Magasin de stockage--</option>
                                    {storeOptions.length > 0 &&
                                        storeOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: 12}}>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Référence</label>
                                <input type="text" className="form-control form-control-sm" value={reference} onChange={(e) => this.handleChange(e, 'reference')} placeholder="Réference"  />
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <label className='nx-label'>Date de péremption *</label>
                                <input type="date" className="form-control form-control-sm" value={expire} onChange={(e) => this.handleChange(e, 'expire')} placeholder="Date de péremption"  required />
                            </div>
                        </div>
                        <fieldset className="form-group" style={{textAlign: 'end' }}>
                            <button onClick={()=>this.setState({ ...initialState, ...initialStateModal })} type="button" className="btn btn-secondary">Fermer</button>
                            <button type="submit" name="valider" style={{marginLeft: 8}} className="btn btn-primary">Ajouter</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>
            </Head>
        )
    }
}