import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const columns = [
    { name: 'libelle', header: 'Libellé' },
    { name: 'symbole', header: 'Symbole' },
]

const initialState = {
    modal: false, modalDelete: false, popLoad: false, action: '',
    libelle: '', symbole: '', currentId: ''
}
export default class Devise extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            data: [],
            tempData: [],
            load: true
        }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }
    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const current = this.state.data.find(d => d.id === id)
        if (current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, libelle, symbole } = this.state
        const data = { id: currentId.toString(), libelle, symbole }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, load: true })
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => td?.libelle.toLowerCase().includes(value.toLowerCase()))
        this.setState({ data, search: value })
    }

    fetch = () => {
        this.props.controller.fetch()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({ data, tempData: data, load: false })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    componentDidMount = () => this.fetch()

    render() {
        const { name } = this.props
        const { data, modal, load, popLoad, libelle, symbole } = this.state
        return (
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                            {load && <Spinner />}
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    data={data}
                                    load={load}
                                    detaillable={false}
                                    printable={false}
                                    deletable={false}
                                    onEdit={(id) => this.handleEditOpen(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Modification de devise</h5>
                    <form method="POST" onSubmit={this.handleSubmitUpdate}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Libellé *</label>
                                    <input type="text" value={libelle} onChange={(e) => this.handleChange(e, 'libelle')} className="form-control form-control-sm" placeholder="Libellé" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Symbole *</label>
                                    <input type="text" value={symbole} onChange={(e) => this.handleChange(e, 'symbole')} className="form-control form-control-sm" placeholder="Symbole" required />
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Modifier</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>

                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>

                </ReactModal>
            </Head>
        )
    }
}