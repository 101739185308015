import Head from '../../components/Head'

const NotFound = ({name})=> {
    return (
        <Head name={"404"} expired={true}>
            <div className="content-body">
                <div className="row">
                    <div className="col-12">
                        <div className="card pull-up ecom-card-1 bg-white">
                            <div className="card-content ecom-card2 height-200">
                                <h5 className="text-muted danger position-absolute p-1">Page non trouvée</h5>
                                <div>
                                    <i className="ft-alert-triangle danger font-large-1 float-right p-1"></i>
                                </div>
                                <div className="progress-stats-container ct-golden-section height-200 position-relative pt-3">
                                    <h3 className="profile-username text-center" style={{marginTop: 32}}>Page que vous essayez d'acceder est introuvable !</h3>
                                    <p className="text-center" style={{marginBottom: 72}}>Veuillez contacter votre administrateur pour plus d'informations.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Head>
    )
}
export default NotFound