import { http } from './config';
import Session from '../session';

export class Service {

  constructor(baseURL, session) {

    // const dbname = session.org === null ? 'sahezcmp_prod' : session.org['name']
    // const dbname = session.org === null ? 'gesprod_test' : session.org['name']
    const currentId = session.user === null ? '0' : session.user['id']
    
    const logoutHandler = (err) => {
      if (err.message.indexOf('401') > 0) {
        // Session.reset()
        // setTimeout(function(){ window.location = '/' }, 2000);
      }
      return {pror: err.message};
    };

    this._get = (url, props) => {
      if(session.org === null){
        window.location = '/' + session.baseUrl + '/expire'
      }else{
        const fin = new Date(session.org.fin).getTime()
        const now = new Date().getTime()
        if(now > fin){
          window.location = '/' + session.baseUrl + '/expire'
        }else{
          return http.get(url, { ...props, currentId, baseURL }).catch(logoutHandler);
        }
      }
    }

    this._post = (url, props) => http.post(url, { ...props, currentId, baseURL }).catch(logoutHandler);

    this._delete = (url, props) => http.delete(url, { ...props, currentId, baseURL }).catch(logoutHandler);

    this._report = (url, props) => http.get(url, { ...props, currentId, baseURL, responseType: 'blob' }).catch(logoutHandler);
 
    this._postReport = (url, props) => http.post(url, { ...props, currentId, baseURL, responseType: 'blob' }).catch(logoutHandler);
    
    // this._import = (url, props) => http.post(url, { ...props, baseURL }).catch(logoutHandler);
    this._import = (url, file, body) => http.upload(baseURL+'/'+url, {file, body, currentId}).catch(logoutHandler)
  }
}

export class EntityService extends Service {

  constructor(domain, session) {
    super('/api/v1/' + domain, session)
    // super('https://codstock.lmdmali.org/api/v1/' + domain, session) 
  }


  save = (data) => (data.id) ? this._post(data.id, { data }) : this._post('/', { data });

  fetch = () => { return this._get(); };

  remove = (id) => this._delete(id.toString());

  // report = () => { return this._get(); };

}
