import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../../components/Spinner'
import Head from '../../../components/Head'
import { Link } from 'react-router-dom'
import Table from '../../../components/Table'
import SearchInput from '../../../components/Search'

const columns = [
    { name: 'libelle', header: 'Libellé' },
    { name: 'unite', header: 'Unité' },
    { name: 'description', header: 'Description' },
]

const initialState = { 
    modal: false, modalDelete: false, modalUnite: false, popLoad: false, action: '',
    libelle: '',  unite_id: '', description: '', libelleUnite: '', currentId: ''
}
export default class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ...initialState,
            data: [],
            tempData: [],
            uniteOptions: [],
            load: true
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSubmitUnite = this.handleSubmitUnite.bind(this)
    }
    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleEditOpen(id){
        const current = this.state.data.find(d=> d.id === id)
        if(current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id){
        if(id) this.setState({ modalDelete: true, currentId: id })
        else toast.error("Une erreur inattendue s'est produite.")
    }

    handleSubmitUnite(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { libelleUnite } = this.state
        const data = { libelle: libelleUnite }

        this.props.uniteController.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                data.id = res.id
                this.setState({ libelleUnite: '', modalUnite: false, uniteOptions: [data, ...this.state.uniteOptions], popLoad: false })
                toast.success(res.message)
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { libelle, description, unite_id } = this.state
        const data = { libelle, description, unite_id }

        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: true })
                toast.success(res.message)
                this.fetch()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, libelle, description, unite_id } = this.state
        const data = {  id: currentId.toString(), libelle, description, unite_id }
        if(currentId){
            this.props.controller.save(data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handleDelete(){
        this.setState({popLoad: true})
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.fetch()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) })
        }
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.libelle.toLowerCase().includes(value.toLowerCase()) ||
                td?.unite.toLowerCase().includes(value.toLowerCase()) ||
                td?.description.toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    getUniteList() {
        this.props.uniteController.fetch()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined') this.setState({ uniteOptions: res }) 
            else toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.")
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    fetch = ()=>{
        this.props.controller.fetch()
        .then(data => {
            if (!data.error && typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, load: false })
            } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    componentDidMount = () => {
        this.fetch()
        this.getUniteList()
    }

    render(){
        const { name } = this.props
        const { data, modal, modalDelete, action, load, popLoad, libelle, description,
            uniteOptions, libelleUnite,  modalUnite, unite_id  } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0">
                                <li><Link to="#" onClick={()=>this.setState({ modal: true, action: 'save'  })}><i className="ft-plus"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}
                            <div className="table-responsive">
                                <Table columns={columns} data={data} load={load} detaillable={false} printable={false} deletable={false}
                                    onEdit={(id) => this.handleEditOpen(id)}
                                    onDelete={(id) => this.handleDeleteOpen(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>{action === 'save' ? "Nouveau produit fini" : "Modification du produit fini"}</h5>

                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate }>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Libellé *</label>
                                    <input type="text" value={libelle}  onChange={(e)=>this.handleChange(e, 'libelle')} className="form-control form-control-sm" placeholder="Libellé" required />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Unité *</label>
                                    <div style={{display: 'flex'}}>
                                        <select className="form-control form-control-sm" value={unite_id} onChange={(e) => this.handleChange(e, 'unite_id')} required >
                                            <option value="">--Unité--</option>
                                                {uniteOptions.length > 0 &&
                                                    uniteOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                                }
                                        </select>
                                        <button onClick={()=>this.setState({ modalUnite: true })} style={{marginLeft: 4, height: 31, width: 36, display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="button" className="btn btn-primary">
                                            <i className="la la-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className='nx-input-label'>Description</label>
                                    <input type="text" value={description}  onChange={(e)=>this.handleChange(e, 'description')} className="form-control form-control-sm" name="description" placeholder="Description"/>
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{textAlign: 'end' }}>
                            <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{marginLeft: 8}} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalUnite} ariaHideApp={false}>
                    <h5>Nouvelle unité de mesure</h5>

                    <form method="POST" onSubmit={this.handleSubmitUnite}>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Libellé *</label>
                                    <input type="text" value={libelleUnite}  onChange={(e)=>this.handleChange(e, 'libelleUnite')} className="form-control form-control-sm" placeholder="Libellé" required />
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{textAlign: 'end' }}>
                            <button onClick={()=>this.setState({ libelleUnite: '', modalUnite: false })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{marginLeft: 8}} className="btn btn-primary">Enregistrer</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion de l'article</h5>

                    <span className="card-text">
                        {`Etes-vous sûr de vouloir supprimer cet article, cette action est irreversible et entrainera la suppression de l'article dans le stock, entrée et sortie.`}
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>

                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>

                </ReactModal>
            </Head>
        )
    }
}