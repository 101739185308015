import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../../components/Spinner'
import Head from '../../../components/Head'
import Session from '../../../session'
import { generatePdf } from '../../print.controller'
import { formatDate, formatDateStrToDateComplete, formatNumber } from '../../../lib/functions'

const paragraphe = {background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {marginBottom: 16, border: '1px solid #ddd', paddingTop: 24, paddingBottom: 24, borderRadius: 8, top: -24, position: 'relative'}
const initialPaiementState = { montantPaiement: 0, montantPaiementEdit: 0, currentPaiementId: null, modal: false, modalDelete: false, modalShip: false, modalQuantity: false, currentDetailId: '', currentDetailApprovisionnementId: '', currentQte: 0, currentQteLivree: 0, currentQteDetail: 0, currentQteRestante: 0, action: '' }

export default class View extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            paiements: [],
            approvisionnement: {},
            ...initialPaiementState,
            popLoad: true,
            currentPaiementReliquat: 0,
        }
        this.handlePaiementSubmitSave = this.handlePaiementSubmitSave.bind(this)
        this.handlePaiementSubmitUpdate = this.handlePaiementSubmitUpdate.bind(this)
        this.handleQuantityShip = this.handleQuantityShip.bind(this)
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = (sum + (data[i]['pu'] * data[i]['quantite']) + (parseInt(data[i]['transport']) + parseInt(data[i]['stockage'])) ) }
        return sum
    }
    calculTotalPaye = (data)=>{
        let total = 0;
        for(let i=0; i<data.length; i++){ total+= data[i]['montant'] }
        return total
    }
    handlePaiementDeleteOpen(id){
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ modalDelete: true, currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handlePaiementEditOpen(id) {
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ montantPaiement: current.montant, montantPaiementEdit: current.montant, modal: true, action: 'update', currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    gen(data){
        generatePdf(data, 'productions')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            this.setState({popLoad: false})
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrint(){
        this.setState({popLoad: true})
        const store = Session.get('$sb_structure')
        this.gen({production: this.state.production, details: this.state.data, store})
    }

    fetch = ()=>{
        const id = this.props.match.params.id
        this.props.controller.details(id)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                // const totalSansTva = this.calculMontant(res.details)
                const currentPaiementReliquat = res.approvisionnement?.total - this.calculTotalPaye(res.paiements)
                this.setState({ approvisionnement: res.approvisionnement, data: res.details, paiements: res.paiements, currentPaiementReliquat, popLoad: false })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    componentDidMount = () => this.fetch()
    handleChange(e, name) { this.setState({ [name]: e.target.value }) }

    // savePaiement = (id, data) => this._post(`/${id}/paiements`, { data })
    // updatePaiement = (idpaie, data) => this._post(`/paiements/${idpaie}`, { data })
    // deletePaiement = (idpaie) => this._delete(`/paiements/${idpaie}`)

    handlePaiementSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { approvisionnement, montantPaiement } = this.state
        const data = { montant: montantPaiement, approvisionnement_id: approvisionnement.id }

        this.props.controller.savePaiement(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialPaiementState, popLoad: false })
                toast.success(res.message)
                this.fetch()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handlePaiementSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        // montantPaiement: 0, currentPaiementId: null,
        const { montantPaiement, currentPaiementId } = this.state
        const data = { montant: montantPaiement}
        if(currentPaiementId){
            this.props.controller.updatePaiement(currentPaiementId, data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handlePaiementSubmitDelete(){
        this.setState({popLoad: true})
        const { currentPaiementId } = this.state
        if(currentPaiementId){
            this.props.controller.deletePaiement(currentPaiementId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handleShip(){
        this.setState({ popLoad: true })
        const { approvisionnement } = this.state
        if(approvisionnement.id){
            this.props.controller.ship({ id: approvisionnement.id, libelle: approvisionnement.libelle })
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handleOpenShip = (id) =>{
        const current = this.state.data.find(d=>d.id === parseInt(id))
        if (current) this.setState({ currentQteDetail: current.quantite, currentQteLivree: current.quantite_livree, currentQteRestante: current.quantite_restante, modalQuantity: true, currentDetailId: id, currentDetailApprovisionnementId: current.approvisionnement_id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleQuantityShip = (e) =>{
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentQte, currentDetailId, currentQteDetail, currentQteRestante, currentQteLivree, currentDetailApprovisionnementId } = this.state
        const data = { 
            id: currentDetailId, 
            quantiteLivree: parseFloat(currentQteLivree) + parseFloat(currentQte), 
            quantiteRestante: parseFloat(currentQteRestante) + parseFloat(currentQte), 
            approvisionnementId: currentDetailApprovisionnementId,
            quantite: currentQteDetail
        }

        this.props.controller.shipQuantity(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialPaiementState, popLoad: false })
                toast.success(res.message)
                this.fetch()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    render(){
        const { name } = this.props
        const { data, paiements, approvisionnement, popLoad, modal, action, montantPaiement, modalDelete, modalShip, modalQuantity, currentQte, currentQteDetail, currentQteLivree, montantPaiementEdit, currentPaiementReliquat } = this.state
            
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{padding: 0, display: 'inline-block'}} onClick={()=>this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{display: 'inline-block'}}>Détail de la commande</h4>
                        <div style={{float: 'right'}}>
                            {/* <button className="btn btn-link" style={{padding: 0}} onClick={()=>this.handlePrint()}>
                                <i className="la la-print"></i>
                            </button> */}
                        </div>
                    </div>
                    <div className="card-content collapse show">
                            <div className="card-body" style={{marginLeft: 14, marginRight: 14}}>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Les informations concernant les coûts. </p>
                                        <div className="row" style={{...row, marginRight: 8}}>
                                            <div className="col-12">
                                                <p style={{marginBottom: 4}}><strong>{`Date de la commande : `}</strong>{formatDate(approvisionnement.date)}</p>
                                                <p style={{marginBottom: 4}}><strong>Coût de transport : </strong>{formatNumber(approvisionnement.transport)} F CFA</p>
                                                <p style={{marginBottom: 4}}><strong>Coût de stockage : </strong>{formatNumber(approvisionnement.stockage)} F CFA</p>
                                                <p style={{marginBottom: 4}}><strong>Autres charges : </strong>{`${formatNumber(approvisionnement.charge)} F CFA`}</p>
                                                <p style={{marginBottom: 4}}><strong>Motif autres charges  : </strong>{approvisionnement.motif}</p>
                                                <p style={{marginBottom: 4}}>Coût de la commande : <strong>{`${formatNumber(approvisionnement.total)} F CFA`}</strong></p>
                                                <p style={{marginBottom: 4}}>Reliquat : <strong>{`${formatNumber(currentPaiementReliquat)} F CFA`}</strong></p>
                                                <p style={{marginBottom: 8}}>Etat de la commande : <strong>{approvisionnement.etat}</strong></p>

                                                {approvisionnement.etat === 'EN COURS' && <button type="button" onClick={()=>this.setState({ modalShip: true })} style={{ padding: '6px 8px' }} className="btn btn-primary">Marquer comme livrée</button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Les informations sur le paiement. </p>
                                        <div className="row" style={{...row, marginRight: 8}}>
                                            <div className="table-responsive">
                                                {currentPaiementReliquat > 0 &&
                                                <button className="btn btn-link" style={{marginBottom: 12, float: 'right'}} onClick={() => this.setState({ modal: true, action: 'save' })}>
                                                   Nouveau paiement
                                                </button>}
                                                <table className="table" id="datatable">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Montant</th>
                                                            <th>Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paiements.length > 0 &&
                                                        paiements.map((d, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{`CMD_PMNT_${d.id}`}</td>
                                                                    <td>{formatNumber(d.montant)} F CFA</td>
                                                                    <td>{formatDateStrToDateComplete(d.date)}</td>
                                                                    <td>
                                                                        <button className="btn btn-link" onClick={() => this.handlePaiementEditOpen(d.id)}>
                                                                            <i className="la la-pencil"></i>
                                                                        </button>
                                                                        <button className="btn btn-link" onClick={() => this.handlePaiementDeleteOpen(d.id)}>
                                                                            <i className="la la-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="card-text" style={paragraphe}>Les informations des matières premières. </p>
                                <div className="row" style={{...row, marginRight: 8}}>
                                    <div className="table-responsive">
                                        <table className="table" id="datatable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Réference</th>
                                                    <th>Libellé</th>
                                                    <th>Expire le</th>
                                                    <th>P.U d'achat</th>
                                                    <th>Qté commandée</th>
                                                    <th>Qté livrée</th>
                                                    <th>Magasin</th>
                                                    <th>Montant</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length > 0 &&
                                                data.map((d, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{`MAT_${d.matiere_id}`}</td>
                                                            <td>{d.reference}</td>
                                                            <td>{d.libelle}</td>
                                                            <td>{formatDate(d.expire)}</td>
                                                            <td>{formatNumber(d.pu)}</td>
                                                            <td>{`${formatNumber(d.quantite)} ${d.unite}`}</td>
                                                            <td>{`${formatNumber(d.quantite_livree)} ${d.unite}`}</td>
                                                            <td>{d.store}</td>
                                                            <td>{formatNumber(d.pu * d.quantite)} F CFA</td>
                                                            <td>
                                                                {d.quantite > d.quantite_livree &&
                                                                <button className="btn btn-link" onClick={() => this.handleOpenShip(d.id)}>
                                                                    <i className="la la-truck"></i>
                                                                </button>}
                                                            </td>
                                                        </tr>
                                                    )
                                                }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Chargement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, les données sont en cours de chargement ...`}
                    </span>
                </ReactModal>

                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>{action === 'save' ? "Nouveau paiement" : "Modification du paiement"}</h5>

                    <form method="POST" onSubmit={action === 'save' ? this.handlePaiementSubmitSave : this.handlePaiementSubmitUpdate}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className='nx-input-label'>Montant *</label>
                                    <input type="number" min={5} value={montantPaiement}  onChange={(e)=>this.handleChange(e, 'montantPaiement')} className="form-control form-control-sm" placeholder="Montant *" required />
                                </div>
                                {action === 'save' && <p>Reliquat: {formatNumber(currentPaiementReliquat)} F CFA</p>}
                                {action === 'update' && <p>Reliquat: {formatNumber(currentPaiementReliquat + montantPaiementEdit)} F CFA</p>}
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                            {action === 'save' && montantPaiement <= currentPaiementReliquat && <button type="submit" name="valider" style={{ marginLeft: 8, padding: '6px 8px' }} className="btn btn-primary">Enregistrer</button>}
                            {action === 'update' && montantPaiement <= currentPaiementReliquat + montantPaiementEdit && <button type="submit" name="valider" style={{ marginLeft: 8, padding: '6px 8px' }} className="btn btn-primary">Modifier</button>}
                            {/* {montantPaiement <= currentPaiementReliquat && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>} */}
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion du paiement</h5>
                    <span className="card-text">
                        Etes-vous sûr de vouloir supprimer ce paiement, cette action est irreversible.
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialPaiementState })} style={{ padding: '6px 8px' }} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handlePaiementSubmitDelete()} style={{ marginLeft: 8, padding: '6px 8px' }} className="btn btn-primary">Supprimer</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={modalShip} ariaHideApp={false}>
                    <h5>Livraison de la commande</h5>
                    <span className="card-text">
                        Etes-vous sûr de vouloir marquer cette commande comme livrée.<br/>

                        Attention, cette action est irreversible.
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialPaiementState })} style={{ padding: '6px 8px' }} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleShip()} style={{ marginLeft: 8, padding: '6px 8px' }} className="btn btn-primary">Valide</button>
                    </div>
                </ReactModal>

                <ReactModal isOpen={modalQuantity} ariaHideApp={false}>
                    <h5>Quantité livrée</h5>

                    <form method="POST" onSubmit={this.handleQuantityShip}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className='nx-input-label'>Quantité livrée *</label>
                                    <input type="number" min={1} max={currentQteDetail - currentQteLivree} value={currentQte}  onChange={(e)=>this.handleChange(e, 'currentQte')} className="form-control form-control-sm" placeholder="Quantité livrée" required />
                                    {currentQteDetail > 0 && <p>Reste à livrer: {formatNumber(currentQteDetail - currentQteLivree)}</p>}
                                </div>
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialPaiementState })} style={{ padding: '6px 8px' }} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{ marginLeft: 8, padding: '6px 8px' }} className="btn btn-primary">Enregistrer</button>
                        </fieldset>
                    </form>
                </ReactModal>
                
            </Head>
        )
    }
}