import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { Link } from 'react-router-dom'

const initialState = {
    matiereId: '',
    // for edit
    editMatiereLibelle: '',
    editMatiereId: '',
    editProduitLibelle: '',
    editProduitId: '',
    //====================Matiere premiere ====================
    pumatiere: '', quantite: '', matierePu: '', matiereQuantite: 0, matiereQuantiteReste: '', matiereLibelle: '', matiereUnite: '', magasin_id: '',

    action: '',
    currentId: '',
}

const initialStateModal = { popLoad: false, modal: false, modalDelete: false }

const initialStateProduit = {
    libelleProduit: '',
    puventeProduit: '',
    descriptionProduit: '',
    modalProduit: false,
}

const paragraphe = { background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11 }
const row = { marginBottom: 16, border: '1px solid #ddd', paddingTop: 24, paddingBottom: 24, borderRadius: 8, top: -24, position: 'relative' }

export default class AddProduction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            ...initialStateProduit,
            ...initialStateModal,
            data: [],
            matiereOptions: [],
            matiereStoreOptions: [],
            produitStoreOptions: [],
            produitOptions: [],
            uniteOptions: [],
            produitSelected: {},
            produitId: '',
            expire: '',
            puGros: '',
            puDetail: '',

            //====================Production===========================
            description: '', date: '', mainOeuvre: '', quantiteProduction: '', autreCharge: '', motifAutreCharge: '',

            storeId: '', produitStoreId: '', displayMatiereOptions: false,

            total: 0,
            tempTotal: 0,
            tva: false,
            frs: false,
            load: true,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveProduit = this.handleSubmitSaveProduit.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data) {
        let sum = 0
        for (let i = 0; i < data.length; i++) { sum = (sum + (data[i]['pu'] * data[i]['quantite'])) }
        return sum
    }

    handleChange(e, name) {
        if (name === 'matiereId') {
            const matiere = this.state.matiereOptions.find(a => a.matiere_id == e.target.value)

            if (typeof (matiere) !== 'undefined') {
                const matiereQuantiteReste =
                    parseFloat(matiere.quantite === '' ? '0' : matiere.quantite) -
                    parseFloat(this.state.quantite === '' ? '0' : this.state.quantite)

                this.setState({ [name]: e.target.value, matierePu: matiere.pu, matiereQuantite: matiere.quantite, matiereQuantiteReste: matiereQuantiteReste, matiereLibelle: matiere.libelle, matiereUnite: matiere.unite, magasin_id: matiere.mg_id })
            }

        } else {
            if (name === 'produitId') {
                const produit = this.state.produitOptions.find(a => a.id == e.target.value)
                if (typeof (produit) !== 'undefined') { this.setState({ [name]: e.target.value, produitSelected: produit, prd: true }) }
            } else {
                if (name === 'mainOeuvre') {
                    const total =
                        parseFloat(this.state.tempTotal) +
                        parseFloat(e.target.value === '' ? '0' : e.target.value) +
                        parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge)

                    this.setState({ [name]: e.target.value, total })
                } else {
                    if (name === 'autreCharge') {
                        const total =
                            parseFloat(this.state.tempTotal) +
                            parseFloat(e.target.value === '' ? '0' : e.target.value) +
                            parseFloat(this.state.mainOeuvre === '' ? '0' : this.state.mainOeuvre)

                        this.setState({ [name]: e.target.value, total })
                    } else {
                        if (name === 'quantite') {
                            const matiereQuantiteReste =
                                parseFloat(this.state.matiereQuantite === '' ? '0' : this.state.matiereQuantite) -
                                parseFloat(e.target.value === '' ? '0' : e.target.value)

                            this.setState({ [name]: e.target.value, matiereQuantiteReste })
                        } else {
                            this.setState({ [name]: e.target.value })
                        }
                    }
                }
            }
        }
    }

    handleStoreChange(e) {
        const storeId = e.target.value
        this.setState({ storeId, matiereOptions: [], displayMatiereOptions: false })
        this.fetchMatieresByStore(storeId)
    }

    handleEditOpen(id) {
        const current = this.state.data.find(d => d.id === id)
        if (current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id) {
        if (id) this.setState({ modalDelete: true, currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleSubmitSaveProduit(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { libelleProduit, uniteProduit, descriptionProduit } = this.state
        const data = { libelle: libelleProduit, unite_id: uniteProduit, description: descriptionProduit }

        this.props.produitController.save(data)
            .then(res => {
                console.log(res)
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ ...initialStateProduit, produitOptions: [data, ...this.state.produitOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch((e) => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        const { quantite, matierePu, matiereQuantiteReste, matiereLibelle, matiereUnite, matiereId, storeId, data } = this.state
        const mat = data.find(d => d.matiereId == matiereId)
        if (typeof (mat) !== 'undefined') {
            toast.error("Cette matière première a déjà été ajouté dans la liste.")
        } else {
            if (parseFloat(matiereQuantiteReste) >= 0) {
                const matiere = {
                    id: data.length + 1,
                    matiereId, 
                    pu: matierePu,
                    quantite: parseFloat(quantite),
                    matiereLibelle, matiereUnite,
                    storeId, matiereQuantiteReste
                }

                const datas = [...data, matiere]
                const t = this.calculMontant(datas)
                const total = t +
                    parseFloat(this.state.mainOeuvre === '' ? '0' : this.state.mainOeuvre) +
                    parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge)
                //  parseFloat(this.state.total)
                this.setState({ data: datas, total, tempTotal: t, ...initialState })
            } else { toast.error("La quantité saisie n'est disponible dans le magasin.") }
        }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { description, date, quantiteProduction, produitId, puDetail, puGros, reference, expire, produitStoreId, mainOeuvre, total, autreCharge, motifAutreCharge, data } = this.state
        if (data.length > 0) {
            const datas = {
                production: {
                    description,
                    date,
                    quantite: quantiteProduction,
                    quantite_restante: quantiteProduction,
                    produit_id: produitId,
                    pu_detail: puDetail,
                    pu_gros: puGros,
                    reference,
                    expire,
                    store_id: produitStoreId,
                    main_oeuvre: mainOeuvre,
                    total,
                    charge: autreCharge ? autreCharge : 0,
                    motif: motifAutreCharge ? motifAutreCharge : 'Autre charge'
                },
                // stock: { produitId, quantite: quantiteProduction },
                details: data
            }

            console.log(datas)
            this.props.controller.save(datas)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {

                        this.setState({
                            ...initialState, ...initialStateModal, produitId: '', data: [], total: 0, tempTotal: 0, autreCharge: '', motifAutreCharge: '',
                            description: '', date: '', mainOeuvre: '', quantiteProduction: '', produitSelected: {}, prd: false,
                        })
                        toast.success(res.message)
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        } else { toast.error("La liste de produits à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }
    handleSubmitUpdate(e) {
        e.preventDefault();
    }

    fetchMatieresByStore(store) {
        this.props.approvisionnementController.stockSelectableByStore(store)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ matiereOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }

                this.setState({ displayMatiereOptions: true })
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchStores(type) {
        this.props.storeController.getStores(type)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    if (type == 'matiere') this.setState({ matiereStoreOptions: res })
                    else this.setState({ produitStoreOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchUnites() {
        this.props.uniteController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ uniteOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchProduits() {
        this.props.produitController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ produitOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    componentDidMount() {
        this.fetchStores('matiere')
        this.fetchStores('produit')
        this.fetchProduits()
        this.fetchUnites()
    }

    render() {
        const { name } = this.props
        const {
            data, produitSelected, prd, modal, action,

            // PRODUCTION STATE
            description, date, quantiteProduction, produitOptions, produitId, puDetail, puGros, reference, expire, produitStoreOptions, produitStoreId, mainOeuvre, total, autreCharge, motifAutreCharge,

            // SELECT MATIERE STATE
            matiereStoreOptions, storeId, displayMatiereOptions, matiereOptions, matiereId, matierePu, matiereQuantite, matiereQuantiteReste, quantite,

            libelleProduit, uniteOptions, uniteProduit, descriptionProduit, modalProduit,

            popLoad } = this.state
        return (
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{ padding: 0, display: 'inline-block' }} onClick={() => this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{ display: 'inline-block' }}>Production d'un nouveau produit fini</h4>
                    </div>
                    <div className="card-content collapse show">
                        <form method="POST" onSubmit={this.handleSubmitSave}>
                            <div className="card-body" style={{ marginLeft: 14, marginRight: 14 }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="card-text" style={paragraphe}>Renseigner les informatios concernant la production. </p>
                                        <div className="row" style={row}>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Description</label>
                                                <input type="text" value={description} onChange={(e) => this.handleChange(e, 'description')} className="form-control form-control-sm" placeholder="Description" />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Date *</label>
                                                <input type="date" value={date} onChange={(e) => this.handleChange(e, 'date')} className="form-control form-control-sm" placeholder="Date" required />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Main d'oeuvre *</label>
                                                <input type="number" value={mainOeuvre} onChange={(e) => this.handleChange(e, 'mainOeuvre')} className="form-control form-control-sm" placeholder="Main d'oeuvre" required />
                                            </div>
                                            <div style={{ width: '100%', height: 16 }}></div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Autres charges</label>
                                                <input type="number" value={autreCharge} onChange={(e) => this.handleChange(e, 'autreCharge')} className="form-control form-control-sm" placeholder="Autres charges" />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Motif autres charges</label>
                                                <input type="text" value={motifAutreCharge} onChange={(e) => this.handleChange(e, 'motifAutreCharge')} className="form-control form-control-sm" placeholder="Motif autres charges" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-9 col-sm-12 col-xs-12">
                                        <p className="card-text" style={paragraphe}>Renseigner les informations concernant le produit fini. </p>
                                        <div className="row" style={{ ...row, marginRight: 8 }}>
                                            <div className="col-12">
                                                <label className='nx-input-label'>Produit fini *</label>
                                                <div style={{ display: 'flex' }}>
                                                    <select className="custom-select" style={{ height: 32 }} onChange={(e) => this.handleChange(e, 'produitId')} value={produitId} required>
                                                        <option selected value="">--Produit fini--</option>
                                                        {produitOptions.length > 0 &&
                                                            produitOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                                        }
                                                    </select>
                                                    <button onClick={() => this.setState({ modalProduit: true })} style={{ marginLeft: 4, height: 31, width: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="button" className="btn btn-primary">
                                                        <i className="la la-plus"></i>
                                                    </button>
                                                </div>

                                                {prd && <div className='row'>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                        <p style={{ marginBottom: 0 }}>DESCRIPTION: {produitSelected.description}</p>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                        <p style={{ marginBottom: 0 }}>UNITÉ: {produitSelected.unite}</p>
                                                    </div>
                                                </div>}
                                            </div>

                                            <div style={{ width: '100%', height: 16 }}></div>

                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <label className='nx-input-label'>Référence *</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="Référence" value={reference} onChange={(e) => this.handleChange(e, 'reference')} required />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <label className='nx-input-label'>Quantité produite *</label>
                                                <input type="number" min="1" className="form-control form-control-sm" placeholder="Quantité" value={quantiteProduction} onChange={(e) => this.handleChange(e, 'quantiteProduction')} required />
                                            </div>

                                            <div style={{ width: '100%', height: 16 }}></div>

                                            <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>P.U. vente detail *</label>
                                                <input type="number" min="1" className="form-control form-control-sm" placeholder="Prix unitaire de vente en detail" value={puDetail} onChange={(e) => this.handleChange(e, 'puDetail')} required />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>P.U vente en gros *</label>
                                                <input type="number" className="form-control form-control-sm" placeholder="Prix unitaire de vente en gros" value={puGros} onChange={(e) => this.handleChange(e, 'puGros')} required />
                                            </div>

                                            <div style={{ width: '100%', height: 16 }}></div>

                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <label className='nx-input-label'>Magasin de stockage *</label>
                                                <select className="custom-select" style={{ height: 32 }} onChange={(e) => this.handleChange(e, 'produitStoreId')} value={produitStoreId} required>
                                                    <option selected value="">--Magasin--</option>
                                                    {produitStoreOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                                </select>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12">
                                                <label className='nx-input-label'>Date de péremption *</label>
                                                <input type="date" className="form-control form-control-sm" placeholder="Date de péremption" value={expire} onChange={(e) => this.handleChange(e, 'expire')} required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <p className="card-text" style={paragraphe}>Information sur le Coût de la production.</p>
                                        <div className="row" style={row}>
                                            <div className="col-12">
                                                <label className='nx-label'>Coût de production : <strong>{`${total} Fcfa`}</strong></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table" id="datatable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Matières premières</th>
                                                <th>P.U d'achat</th>
                                                <th>Quantité</th>
                                                <th>Montant</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length > 0 &&
                                                data.map((d, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{d.matiereLibelle}</td>
                                                            <td>{d.pu}</td>
                                                            <td>{`${d.quantite} (${d.matiereUnite})`}</td>
                                                            <td>{(d.pu * d.quantite)}</td>
                                                            <td>
                                                                {/* <button className="btn btn-link" onClick={()=>this.handleEditOpen(d.id)} style={{padding: 0}}>
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </button> */}
                                                                <button className="btn btn-link" onClick={() => {
                                                                    const datas = data.filter(f => f.id !== d.id)
                                                                    const t = this.calculMontant(datas)
                                                                    const total = t +
                                                                        parseFloat(this.state.mainOeuvre === '' ? '0' : this.state.mainOeuvre)
                                                                    parseFloat(this.state.autreCharge === '' ? '0' : this.state.autreCharge)

                                                                    this.setState({ data: datas, total, tempTotal: t, ...initialState })
                                                                }}>
                                                                    <i className="la la-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                    <Link to="#" className="btn btn-default" onClick={() => this.setState({ modal: true, action: 'save' })}>Ajouter une matiere première</Link>
                                </div>
                            </div>
                            <div style={{ textAlign: 'end', paddingRight: 16, paddingBottom: 16 }}>
                                <button onClick={() => this.props.history.goBack()} type="button" className="btn btn-secondary" style={{ color: '#fff' }}>Annuler</button>
                                <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Nouvelle matière première</h5>

                    <form method="POST" onSubmit={this.handleSubmitAddElement}>

                        <p className="card-text">Renseigner les Matières premières qui rentrent dans la production de ce produit. </p>
                        <div className="row" style={{ marginBottom: 12 }} >
                            <div className="col-12">
                                <label className='nx-input-label'>Magasin des matières premières *</label>
                                <select className="custom-select form-control-sm" onChange={(e) => this.handleStoreChange(e)} value={storeId} required>
                                    <option selected>--Magasin--</option>
                                    {matiereStoreOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                </select>
                            </div>
                        </div>
                        {displayMatiereOptions && <>
                            <div className="row" style={{ marginBottom: 12 }}>
                                <div className="col-12">
                                    <label className='nx-input-label'>Matières premières *</label>
                                    <select className="custom-select form-control-sm" onChange={(e) => this.handleChange(e, 'matiereId')} value={matiereId} required>
                                        <option selected>Matières premières</option>
                                        {matiereOptions.length > 0 &&
                                            matiereOptions.map((a, i) => <option value={a.matiere_id} key={i}>{`${a.libelle} -- ${a.pu} F CFA`}</option>)
                                        }
                                    </select>
                                    {matierePu !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>P.U:</strong> {matierePu}</p>}
                                    {matiereId !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Qté:</strong> {matiereQuantite}</p>}
                                    {matiereQuantiteReste !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Qté restante:</strong> {matiereQuantiteReste}</p>}
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: 12 }}>
                                <div className="col-12">
                                    <label className='nx-input-label'>Quantité *</label>
                                    <input type="number" className="form-control form-control-sm" min="0.0001" max={matiereQuantite} step="0.0001" value={quantite} onChange={(e) => this.handleChange(e, 'quantite')} placeholder="Quantité" required />
                                </div>
                                {/* <div className="col-6">
                                <input type="number" className="form-control" value={pumatiere} min="5" onChange={(e) => this.handleChange(e, 'pumatiere')} placeholder="Prix unitaire"  required />
                            </div> */}
                            </div>
                        </>}
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialState, ...initialStateModal })} type="button" className="btn btn-secondary">Fermer</button>
                            {displayMatiereOptions && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Ajouter</button>}
                        </fieldset>

                    </form>
                </ReactModal>

                <ReactModal isOpen={modalProduit} ariaHideApp={false}>
                    <h5>Nouveau produit fini</h5>
                    <form method="POST" onSubmit={this.handleSubmitSaveProduit}>
                        <p className="card-text">Renseigner les informations concernant le produit fini.</p>
                        <div className="row">
                            <div className="col-6">
                            <label className='nx-input-label'>Libellé *</label>
                                <input type="text" value={libelleProduit} onChange={(e) => this.handleChange(e, 'libelleProduit')} className="form-control form-control-sm" placeholder="Libellé" required />
                            </div>
                            <div className="col-6">
                                <label className='nx-input-label'>Unité de mésure *</label>
                                <select className="custom-select" style={{ height: 32 }} onChange={(e) => this.handleChange(e, 'uniteProduit')} value={uniteProduit} required>
                                    <option selected value="">--Unité de mésure--</option>
                                    {uniteOptions.length > 0 &&
                                        uniteOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                    }
                                </select>
                            </div>

                            <div className="col-12" style={{ marginBottom: 12, marginTop: 12 }}>
                                <label className='nx-input-label'>Description</label>
                                <input type="text" value={descriptionProduit} onChange={(e) => this.handleChange(e, 'descriptionProduit')} className="form-control form-control-sm" name="description" placeholder="Description" />
                            </div>
                        </div>
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialStateProduit })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>
            </Head>
        )
    }
}