import React from 'react'
import ReactModal from 'react-modal'
import { Line, defaults, Bar } from 'react-chartjs-2'
import { ToastContainer, toast } from 'react-toastify'
import Spinner from '../../components/Spinner'
import { formatNumber }  from '../../lib/functions'

defaults.global.defaultFontFamily = 'ubuntu'
defaults.global.defaultFontColor = '#41526e'

export default class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            popLoad: true,
            nbArticle: 0, nbCritique: 0, nbEpuise: 0, qteStock: 0,
            nbCategorie: 0, nbVente: 0, nbProduction: 0, nbDevis: 0,
            dataCritique: [],
            dataEpuise: [],
            week: {productions: [], depenses: [], ventes: []},
            year: {productions: [], depenses: [], ventes: []},
            card: { productions: 0, depenses: 0, ventes: 0 }
        }
    }

    getCard(){
        this.props.controller.card()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ card: res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    getWeek(date){
        this.props.controller.week({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ week: res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    getYear(date){
        this.props.controller.year({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ year: res, popLoad: false }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
        })
        .catch(()=> {toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    componentDidMount(){
        const date = new Date()
        const str = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`

        this.getCard()
        this.getWeek(str)
        this.getYear(`${date.getFullYear()}`)
    }

    render(){
        const { week, year, card, popLoad } = this.state
        
        const barVente = {
            labels: ['Dimance', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            datasets: [
               {
                label: 'Ventes', backgroundColor: '#b53da2', borderColor: '#b53da2',
                borderWidth: 5, hoverBackgroundColor: '#b53da2', hoverBorderColor: '#b53da2',
                data: week.ventes,
              }
            ]
          }
          const barProduction = {
            labels: ['Dimance', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            datasets: [
               {
                label: 'Productions',
                backgroundColor: '#fdb901',
                borderColor: '#fdb901',
                borderWidth: 5,
                hoverBackgroundColor: '#fdb901',
                hoverBorderColor: '#fdb901',
                data: week.productions,
              }
            ]
          }

          const barApprovisionnement ={
            labels: ['Dimance', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            datasets: [
               {
                label: 'Approvisionnement',
                backgroundColor: '#6967ce',
                borderColor: '#6967ce',
                borderWidth: 5,
                hoverBackgroundColor: '#6967ce',
                hoverBorderColor: '#6967ce',
                data: week.approvisionnements,
              }
            ]
          }
          const barDepense = {
            labels: ['Dimance', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            datasets: [
               {
                label: 'Charges fixes',
                backgroundColor: 'transparent',
                borderColor: '#32CD32',
                borderWidth: 5,
                hoverBackgroundColor: '#32CD32',
                hoverBorderColor: '#32CD32',
                data: week.depenses,
              }
            ]
          }

        const barYear = {
            labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Semptembre', 'Octobre', 'Novembre', 'Decembre'],
            datasets: [
                { label: 'Ventes', backgroundColor: 'transparent', borderColor: 'yellow', borderWidth: 5, hoverBackgroundColor: 'yellow', hoverBorderColor: 'yellow',
                    data: year.ventes,
                },
                {label: 'Productions', backgroundColor: 'transparent', borderColor: '#fdb901', borderWidth: 5, hoverBackgroundColor: '#fdb901', hoverBorderColor: '#fdb901',
                    data: year.productions,
                },
                {label: 'Approvisionnement', backgroundColor: 'transparent', borderColor: '#fa626b', borderWidth: 5, hoverBackgroundColor: '#fa626b', hoverBorderColor: '#fa626b',
                    data: year.approvisionnements,
                },
                {label: 'Charges fixes', backgroundColor: 'transparent', borderColor: '#32CD32', borderWidth: 5, hoverBackgroundColor: '#32CD32', hoverBorderColor: '#32CD32',
                    data: year.depenses,
                }
            ]
        }

        return(
            <div className="app-content content">
                <ToastContainer />
            <div className="content-wrapper">
                <div className="content-wrapper-before" style={{height: 300}}></div>
                <div className="content-header row"></div>
                <div className="content-body">
                    {/* <!-- Chart --> */}
                    <div className="row match-height">
                        <div className="col-12">
                            <div className="">
                                {/* <div id="gradient-line-chart1" className="height-250 GradientlineShadow1"></div> */}
                                <Line data={barYear} height={250} options={{ responsive: true, scaleFontColor: '#ffffff', maintainAspectRatio: false, }} />
                            </div>
                        </div>
                    </div>
                    {/* <!-- Chart -->
                    <!-- eCommerce statistic --> */}
                    <div className="row">
                        <div className="col-xs-12 col-md-12 col-xl-6">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2">
                                    <h5 className="text-muted warning position-absolute p-1">Productions</h5>
                                    <div>
                                        <i className="ft-shopping-cart warning font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3  ">
                                        <Bar data={barProduction} options={{ responsive: true, maintainAspectRatio: true, }} />
                                        <div id="progress-stats-line-chart" className="progress-stats-shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12 col-xl-6">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2">
                                    <h5 className="text-muted primary position-absolute p-1">Approvisionnements</h5>
                                    <div>
                                        <i className="la la-truck primary font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3  ">
                                        <Bar data={barApprovisionnement} options={{ responsive: true, maintainAspectRatio: true, }} />
                                        <div id="progress-stats-line-chart" className="progress-stats-shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12 col-xl-6">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2">
                                    <h5 className="text-muted info position-absolute p-1">Ventes</h5>
                                    <div>
                                        <i className="ft-activity info font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <Bar data={barVente} options={{ responsive: true, maintainAspectRatio: true, }}  />
                                        <div id="progress-stats-line-chart1" className="progress-stats-shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-12 col-xl-6">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2">
                                    <h5 className="text-muted danger position-absolute p-1">Charges fixes</h5>
                                    <div>
                                        <i className="ft-pie-chart danger font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <Line data={barDepense} options={{ responsive: true, maintainAspectRatio: true, }} />
                                        <div id="progress-stats-line-chart2" className="progress-stats-shadow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted warning position-absolute p-1">Productions</h5>
                                    <div>
                                        <i className="ft-shopping-cart warning font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <h3 className="profile-username text-center">{formatNumber(`${card.production}`)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted primary position-absolute p-1">Approvisionnement</h5>
                                    <div>
                                        <i className="la la-truck primary font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <h3 className="profile-username text-center">{formatNumber(`${card.approvisionnement}`)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted info position-absolute p-1">Ventes</h5>
                                    <div>
                                        <i className="ft-activity info font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <h3 className="profile-username text-center">{formatNumber(`${card.vente}`)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card pull-up ecom-card-1 bg-white">
                                <div className="card-content ecom-card2 height-100">
                                    <h5 className="text-muted danger position-absolute p-1">Charges fixes</h5>
                                    <div>
                                        <i className="ft-pie-chart danger font-large-1 float-right p-1"></i>
                                    </div>
                                    <div className="progress-stats-container ct-golden-section height-75 position-relative pt-3">
                                        <h3 className="profile-username text-center">{formatNumber(`${card.depense}`)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ReactModal isOpen={popLoad} ariaHideApp={false}>
                        <h5>Chargement des données ...</h5>
                        <Spinner />
                        <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                            {`Veuillez patienter, les données sont en cours de chargement ...`}
                        </span>
                    </ReactModal>
                </div>
            </div>
        </div>
        )
    }
}