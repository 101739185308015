import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { Link } from 'react-router-dom'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'
import { formatDate } from '../../lib/functions'

const columns = [
    { name: 'id', header: 'ID', prefix: 'PRD_' },
    { name: 'reference', header: 'Référence' },
    { name: 'produit', header: 'Produit fini'},
    { name: 'quantite', header: 'Quantité', suffix: '' },
    { name: 'unite', header: 'Unité'},
    { name: 'total', header: 'Coût total', suffix: 'F cfa' },
    { name: 'date', header: 'Date production', date: 'date-fr' },
    { name: 'expire', header: 'Expire le', date: 'date-fr' },
]

const initialState = { 
    modal: false, modalDelete: false, action: '', load: true,
    libelle: '', description: '', currentId: ''
}
export default class Production extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ...initialState,
            data: [],
            tempData: [],
        }
        this.handleDelete = this.handleDelete.bind(this)
        this.handleDetroy = this.handleDetroy.bind(this)
    }
    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleView(id){ this.props.history.push('productions/'+id) }
    handleEditOpen(id){
        const current = this.state.data.find(d=> d.id === id)
        if(current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id){
        const current = this.state.data.find(d=> d.id === id)
        if(current) this.setState({ modalDelete: true, currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleDelete(){
        this.setState({ modal: true, modalDelete: false })
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.fetch()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) })
        }
    }
    handleDetroy(){
        this.setState({ modal: true, modalDelete: false })
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId+'/destroy')
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.fetch()                    
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, load: false }) })
        }
    }

    gen(data){
        generatePdf(data, 'productions')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrintOne(id){
        this.setState({modal: true})
        const store = Session.get('$sb_structure')
        
        this.props.controller.details(id)
        .then(res=>{
            console.log(res)
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: false })
                this.gen({...res, store})
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.description.toLowerCase().includes(value.toLowerCase()) ||
                td?.libelle.toLowerCase().includes(value.toLowerCase()) ||
                td?.quantite.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.total.toString().toLowerCase().includes(value.toLowerCase()) ||
                formatDate(td?.date).toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    fetch = () =>{
        this.props.controller.fetch()
        .then(data => {
            console.log(data)
            if (!data.error && typeof (data.pror) === 'undefined') {
                this.setState({ data, tempData: data, load: false })
            } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    componentDidMount = () => this.fetch()

    render(){
        const { name } = this.props
        const { data, modal, modalDelete, load, } = this.state
        return(
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Liste</h4>
                        {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a> */}
                        <div className="heading-elements">
                            <ul className="nx-add-ul mb-0">
                                {/* <li><Link to="#"><i className="ft-printer"></i></Link></li> */}
                                <li><Link to="productions-new"><i className="ft-plus"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                            {load && <Spinner />}   
                            <div className="table-responsive">
                                <Table columns={columns} data={data} load={load} editable={false} countable={false}
                                    onView={(id) => this.handleView(id)}
                                    onPrint={(id) => this.handlePrintOne(id)}
                                    onDelete={(id) => this.handleDeleteOpen(id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Traitement ...</h5>
                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>
                </ReactModal>

                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                    <h5>Suppresion de la production</h5>
                    <span className="card-text">
                        Etes-vous sûr de vouloir supprimer cette production ? <br/>
                        Toutes les ventes liées au produit fini issu de cette production seront supprimées.  <br/>
                        Attention, cette action est irreversible!
                    </span>

                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                        <button onClick={() => this.setState({ ...initialState, load: false })} type="button" className="btn btn-secondary">Annuler</button>
                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                    </div>
                </ReactModal>
            </Head>
        )
    }
}