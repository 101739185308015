import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Head from '../../components/Head'
import { Link } from 'react-router-dom'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import { formatNumber } from '../../lib/functions'

const initialState = {
    produitId: '',
    // for edit
    editProduitLibelle: '',
    editProduitId: '',
    editClientLibelle: '',
    editClientId: '',
    //===========
    quantite: '', unite: '', produitPuGro: '', produitLibelle: '', puvente: '', produitPuDetail: '', produitTypePrix: '', produitQuantite: '', produitQuantiteReste: '',
    action: '',
    currentId: '',
}

const initialStateModal = { popLoad: false, modal: false, modalDelete: false }

const initialStateClient = {
    nomClient: '',
    telephoneClient: '',
    emailClient: '',
    adresseClient: '',
    societeClient: '',
    modalClient: false,
}

const paragraphe = { marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11 }
const row = { margin: 0, border: '1px solid #ddd', paddingTop: 24, borderRadius: 8, top: -24, position: 'relative' }

export default class AddVente extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            produitOptions: [],
            clientOptions: [],
            clientSelected: {},
            clientId: '',
            date: '',
            total: 0,
            tempTotal: 0,
            ntva: 18,
            tva: false,
            editTva: false,
            clt: false,
            load: true,
            ...initialState,
            ...initialStateModal,
            ...initialStateClient,
            montantPaye: 0,

            storeId: '',
            displayProduitOptions: false,
            produitStoreOptions: []
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveClient = this.handleSubmitSaveClient.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data) {
        let sum = 0
        for (let i = 0; i < data.length; i++) { sum = sum + (data[i]['puvente'] * data[i]['quantite']) }
        return sum
    }

    handleChange(e, name) {
        if (name === 'produitId') {
            const produit = this.state.produitOptions.find(a => a.produit_id == e.target.value)
            console.log(produit)
            if (typeof (produit) !== 'undefined') {
                const produitQuantiteReste =
                    parseFloat(produit.quantite === '' ? '0' : produit.quantite) -
                    parseFloat(this.state.quantite === '' ? '0' : this.state.quantite)

                this.setState({ 
                    [name]: e.target.value, 
                    unite: produit.unite, 
                    produitPuGro: produit.pu_gros,
                    produitLibelle: produit.libelle,
                    produitPuDetail: produit.pu_detail,
                    produitQuantite: produit.quantite, 
                    produitQuantiteReste: produitQuantiteReste 
                })

                // this.setState({ [name]: e.target.value, produitPuGro: produit.description, produitLibelle: produit.libelle, puvente: produit.puvente })
            }
        } else {
            if (name === 'clientId') {
                const client = this.state.clientOptions.find(a => a.id == e.target.value)
                if (typeof (client) !== 'undefined') { this.setState({ [name]: e.target.value, clientSelected: client, clt: true }) }
            } else {
                if (name === 'quantite') {
                    const produitQuantiteReste =
                        parseFloat(this.state.produitQuantite === '' ? '0' : this.state.produitQuantite) -
                        parseFloat(e.target.value === '' ? '0' : e.target.value)

                    this.setState({ [name]: e.target.value, produitQuantiteReste })
                } else {
                    this.setState({ [name]: e.target.value })
                }
            }
        }
    }

    handleStoreChange(e) {
        const storeId = e.target.value
        this.setState({ storeId, produitOptions: [], displayProduitOptions: false })
        this.fetchProduitsByStore(storeId)
    }

    handleSetTva(tva) {
        const { total, tempTotal, ntva } = this.state
        if (tva) {
            // const t = total + (total*0.18)
            const t = total + (total * (ntva / 100))
            this.setState({ total: t, tva })
        } else {
            this.setState({ total: tempTotal, tva })
        }
    }

    changeTva() {
        const { tempTotal, ntva, tva } = this.state
        if (tva) {
            const t = tempTotal + (tempTotal * (ntva / 100))
            this.setState({ total: t, tva, editTva: false })
        } else {
            this.setState({ total: tempTotal, tva, editTva: false })
        }
    }

    handleEditOpen(id) {
        const current = this.state.data.find(d => d.id === id)
        if (current) this.setState({ ...current, modal: true, action: 'update', currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleDeleteOpen(id) {
        if (id) this.setState({ modalDelete: true, currentId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleSubmitSaveClient(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { nomClient, telephoneClient, emailClient, adresseClient, societeClient } = this.state
        const data = { nom: nomClient, telephone: telephoneClient, email: emailClient, adresse: adresseClient, societe: societeClient }

        this.props.clientController.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ ...initialStateClient, clientOptions: [data, ...this.state.clientOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        // const { produit_id, produitLibelle, produitPuGro, puvente, quantite, data, tva, ntva } = this.state
        // const produit = { id: data.length +1, produit_id, libelle: produitLibelle, description: produitPuGro, puvente, quantite }

        // const datas = [...data, produit]
        // const t = this.calculMontant(datas)
        // // const total = tva ? t+(t*0.18) : t
        // const total = tva ? t+(t*(ntva/100)) : t
        // this.setState({ data: datas, total, tempTotal: t, ...initialState })

        const { quantite, unite, produitTypePrix, storeId, produitQuantiteReste, produitLibelle, produitId, data, tva, ntva } = this.state
        const test = data.find(d => d.produit_id == produitId)
        if (typeof (test) !== 'undefined') {
            toast.error("Ce produit fini a déjà été ajouté dans la liste.")
        } else {
            if (parseFloat(produitQuantiteReste) >= 0) {
                const produit = {
                    id: data.length + 1,
                    produitId,
                    libelle: produitLibelle,
                    // description: produitPuGro,
                    puvente: produitTypePrix,
                    quantite: parseFloat(quantite),
                    unite: unite,
                    storeId, 
                    produitQuantiteReste
                }

                const datas = [...data, produit]
                const t = this.calculMontant(datas)
                const total = tva ? t + (t * (ntva / 100)) : t

                this.setState({ data: datas, total, tempTotal: t, ...initialState })
            } else { toast.error("La quantité saisie n'est disponible dans le stock de produits finis.") }
        }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        this.save(this.state.action)
    }

    save(action) {
        const { clientId, total, date, data, tva, ntva, clientSelected, montantPaye } = this.state
        if (data.length > 0) {
            if (Number(montantPaye) <= Number(total)) {
                const datas = { 
                    vente: { 
                        client_id: clientId ? clientId : 1,
                        montant: total, 
                        paye: total, 
                        date, 
                        devis: 0, 
                        tva: tva ? ntva : '0'
                    },
                    details: data, montantPaye: montantPaye ? montantPaye : 0 
                }
                this.props.controller.save(datas)
                    .then(res => {
                        if (!res.error && typeof (res.pror) === 'undefined') {
                            if (action === 'save') {
                                this.setState({ ...initialState, ...initialStateModal, clientId: '', data: [], total: 0, tempTotal: 0, montantPaye: 0, date: '', clientSelected: {}, clt: false, tva: false })
                                toast.success(res.message)
                            } else {
                                if (action === 'print') {
                                    this.handlePrint(datas, res.id, clientSelected, false)
                                    toast.success(res.message)
                                } else {
                                    if (action === 'send') {
                                        this.handlePrint(datas, res.id, clientSelected, true)
                                        toast.success(res.message + ' Patientez votre application de messagerie sera lancée.')
                                    }
                                }
                            }
                        }
                        else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                    })
                    .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
            } else { toast.error("Le montant avancé n'est pas être superieur à celui de la vente."); this.setState({ popLoad: false }) }
        } else { toast.error("La liste de produits à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }

    gen(data, send) {
        generatePdf(data, 'ventes')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                if (!send) {
                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                    pri.document.open();
                    pri.document.write(temp.innerHTML);
                    pri.print();
                    this.setState({ ...initialState, ...initialStateModal, clientId: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                    pri.document.close();
                } else {
                    window.location.href = `mailto:${this.state.clientSelected.email}?subject=Facture`
                    this.setState({ ...initialState, ...initialStateModal, clientId: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                }
            }).catch(err => console.log(err))
    }

    handlePrint(data, id, client, send) {
        this.setState({ popLoad: true })
        const store = Session.get('$sb_structure')
        this.gen({ facture: { ...data.vente, id, nom: client.nom, societe: client.societe, telephone: client.telephone, email: client.email, adresse: client.adresse }, details: data.details, store }, send)
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, produitId, quantite, client, date } = this.state
        const data = { id: currentId.toString(), produitId, quantite: parseInt(quantite), client, date, type: 'in' }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, ...initialStateModal, modal: false })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId } = this.state
        if (currentId) {
            this.props.controller.remove(currentId)
                .then(res => {
                    console.log(res)
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, initialStateModal })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, ...initialStateModal }) })
        }
    }

    getClientList() {
        this.props.clientController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ clientOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchProduitsByStore(storeId) {
        this.props.productionController.stockSelectableByStore(storeId)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ produitOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
                this.setState({ displayProduitOptions: true })
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchStores() {
        this.props.storeController.getStores("produit")
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ produitStoreOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    componentDidMount() {
        // this.getProduitList()
        this.getClientList()
        this.fetchStores()
    }

    render() {
        const { name } = this.props
        const { data, clientOptions, produitOptions, clientSelected, clt, modal,
            quantite, produitPuGro, produitPuDetail, produitQuantite, produitId, produitQuantiteReste, total, tva, editTva, ntva,
            nomClient, telephoneClient, emailClient, adresseClient, societeClient, modalClient, montantPaye,
            produitStoreOptions, storeId, displayProduitOptions, produitTypePrix, unite,
            load, popLoad } = this.state
        return (
            <Head name={name}>
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-link" style={{ padding: 0, display: 'inline-block' }} onClick={() => this.props.history.goBack()}>
                            <i className="la la-arrow-left"></i>
                        </button>
                        <h4 className="card-title" style={{ display: 'inline-block' }}>Enregistrement d'une nouvelle facture</h4>
                    </div>
                    <div className="card-content collapse show">
                        <form method="POST" onSubmit={this.handleSubmitSave}>
                            <div className="card-body" style={{ marginLeft: 14, marginRight: 14, padding: 0 }}>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Information sur le client et la date de la facture.</p>
                                        <div className="row" style={{ ...row, marginRight: 8 }}>
                                            <div className="col-sm-6">
                                                <label className='nx-input-label'>Client</label>
                                                <div style={{ display: 'flex' }}>
                                                    <select className="custom-select form-control form-control-sm" style={{ height: 32 }} onChange={(e) => this.handleChange(e, 'clientId')} >
                                                        <option value="">--Client--</option>
                                                        {clientOptions.length > 0 &&
                                                            clientOptions.map((a, i) => <option value={a.id} key={i}>{`${a.nom} ; Tel: ${a.telephone}`}</option>)
                                                        }
                                                    </select>
                                                    <button onClick={() => this.setState({ modalClient: true })} style={{ marginLeft: 4, height: 31, width: 36, display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="button" className="btn btn-primary">
                                                        <i className="la la-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Date *</label>
                                                    <input onChange={(e) => this.handleChange(e, 'date')} type="date" className="form-control form-control-sm" placeholder="Date" required />
                                                </div>
                                            </div>
                                            {clt && <>
                                                <div className="col-sm-3">
                                                    <h6 style={{ fontSize: 12, marginBottom: 0 }}>Société</h6>
                                                    <p style={{ fontSize: 10 }}>{clientSelected.societe}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h6 style={{ fontSize: 12, marginBottom: 0 }}>Téléphone</h6>
                                                    <p style={{ fontSize: 10 }}>{clientSelected.telephone}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h6 style={{ fontSize: 12, marginBottom: 0 }}>Email</h6>
                                                    <p style={{ fontSize: 10 }}>{clientSelected.email}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                    <h6 style={{ fontSize: 12, marginBottom: 0 }}>Adresse</h6>
                                                    <p style={{ fontSize: 10 }}>{clientSelected.adresse}</p>
                                                </div>
                                            </>}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Information sur le montant de la facture</p>
                                        <div className="row" style={row}>
                                            <div className="col-12">
                                                <label className='nx-label'>Montant de la facture : <strong>{`${total} Fcfa`}</strong></label>
                                            </div>
                                            <div className="col-12" style={{ marginBottom: 12 }}>
                                                {!editTva &&
                                                    <>
                                                        <label className='nx-input-label form-check-label' style={{ marginRight: 8 }} >{`Appliquer la TVA (${ntva}%) `}</label>
                                                        <button onClick={() => this.setState({ editTva: true })} style={{ marginRight: 38, padding: '0 4px', fontSize: 10 }} type="button" className="btn btn-primary">
                                                            <i className="la la-pencil"></i>
                                                        </button>
                                                        <input className="form-check-input" type="checkbox" onChange={(e) => this.handleSetTva(e.target.checked)} checked={tva} />
                                                    </>}
                                                {editTva &&
                                                    <div className="form-group" style={{ display: 'flex' }}>
                                                        <input onChange={(e) => this.handleChange(e, 'ntva')} min="0" value={ntva} type="number" className="form-control form-control-sm" placeholder="TVA" />
                                                        <button onClick={() => this.changeTva()} style={{ height: 31, marginLeft: 4, padding: '0 8px' }} type="button" className="btn btn-primary">Valider</button>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-6">
                                        <p className="card-text" style={paragraphe}>Paiement.</p>
                                        <div className="row" style={row}>
                                            <div className="col-12">
                                                <label className='nx-input-label'>Montant payé</label>
                                                <input type="number" value={montantPaye} onChange={(e) => this.handleChange(e, 'montantPaye')} className="form-control form-control-sm" placeholder="Montant" />
                                                <label className='nx-label' style={{ marginTop: 8 }}>Reste à payer (Reliquat) : <strong>{`${formatNumber(total - montantPaye)} Fcfa`}</strong></label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <p style={{ ...paragraphe, marginTop: 0 }}>Information sur la facture et les produits </p>
                                <div className="row" style={row}>
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table" id="datatable">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Libellé</th>
                                                        <th>Prix unitaire</th>
                                                        <th>Quantité</th>
                                                        <th>Montant</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length > 0 &&
                                                        data.map((d, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{d.libelle}</td>
                                                                    <td>{formatNumber(d.puvente)} F CFA</td>
                                                                    <td>{`${formatNumber(d.quantite)} (${d.unite})`}</td>
                                                                    <td>{formatNumber(d.puvente * d.quantite)} F CFA</td>
                                                                    <td>
                                                                        <button className="btn btn-link" onClick={() => {
                                                                            const datas = data.filter(f => f.id !== d.id)
                                                                            const t = this.calculMontant(datas)
                                                                            // const total = tva ? t+(t*0.18) : t
                                                                            const total = tva ? t + (t * (ntva / 100)) : t
                                                                            this.setState({ data: datas, total, tempTotal: t, ...initialState })
                                                                        }}>
                                                                            <i className="la la-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                            <Link to="#" className="btn btn-default" onClick={() => this.setState({ modal: true, action: 'save' })}>Ajouter un produit</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ justifyContent: 'flex-end', padding: '0 16px 16px 16px', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                                <button onClick={() => this.props.history.goBack()} type="button" className="btn btn-secondary">Annuler</button>
                                <button type="submit" onClick={() => this.setState({ action: 'save' })} className="btn btn-primary">Enregistrer</button>
                                <button type="submit" onClick={() => this.setState({ action: 'print' })} className="btn btn-primary">Enregistrer & Imprimer</button>
                                {/* <button type="submit" onClick={()=>this.setState({action: 'send'})} className="btn btn-primary">Enregistrer & Envoyer</button> */}
                            </div>
                        </form>
                    </div>
                </div>
                <ReactModal isOpen={modal} ariaHideApp={false}>
                    <h5>Nouveau produit fini.</h5>

                    <form method="POST" onSubmit={this.handleSubmitAddElement}>

                        <div className="row" style={{ marginBottom: 12 }} >
                            <div className="col-12">
                                <label className='nx-input-label'>Magasin des produits finis *</label>
                                <select className="custom-select form-control-sm" onChange={(e) => this.handleStoreChange(e)} value={storeId} required>
                                    <option selected>--Magasin--</option>
                                    {produitStoreOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                </select>
                            </div>
                        </div>

                        {/* <div className="row" style={{ marginBottom: 12 }} >
                            <div className="col-12">
                                <label className='nx-input-label'>Produit fini *</label>
                                <select className="custom-select form-control-sm" onChange={(e) => this.handleChange(e, 'produit_id')} value={produit_id} required>
                                    <option selected value="">Produit fini</option>
                                    {produitOptions.length > 0 &&
                                        produitOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                    }
                                </select>
                                {produitPuGro !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Description:</strong> {produitPuGro}</p>}
                                {produitPuDetail !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Prix unitaire de vente:</strong> {produitPuDetail}</p>}
                                {produitQuantite !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Quantité:</strong> {produitQuantite}</p>}
                                {produitQuantiteReste !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Quantité restante:</strong> {produitQuantiteReste}</p>}
                            </div>
                        </div> */}
                        {displayProduitOptions && <>
                            <div className="row" style={{ marginBottom: 12 }}>
                                <div className="col-12">
                                    <label className='nx-input-label'>Produit fini *</label>
                                    <select className="custom-select form-control-sm" onChange={(e) => this.handleChange(e, 'produitId')} value={produitId} required>
                                        <option selected value="">Produit fini</option>
                                        {produitOptions.length > 0 &&
                                            produitOptions.map((a, i) => <option value={a.produit_id} key={i}>{a.libelle}</option>)
                                        }
                                    </select>
                                    {produitPuGro !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Prix unitaire grossiste: </strong> {produitPuGro} F FCFA</p>}
                                    {produitPuDetail !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Prix unitaire détaillant: </strong> {produitPuDetail} F FCFA</p>}
                                    {produitQuantite !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Quantité:</strong> {produitQuantite} {unite}</p>}
                                    {produitQuantiteReste !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Quantité restante:</strong> {produitQuantiteReste} {unite}</p>}
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: 12 }}>
                                <div className="col-6">
                                    <label className='nx-input-label'>Quantité *</label>
                                    <input type="number"
                                        className="form-control form-control-sm"
                                        min="0.0001" max={produitQuantite} step="0.0001" value={quantite} onChange={(e) => this.handleChange(e, 'quantite')} placeholder="Quantité" required />
                                </div>
                                <div className="col-6">
                                    <label className='nx-input-label'>Type de Prix à appliquer *</label>
                                    <select className="custom-select form-control-sm" value={produitTypePrix} onChange={(e)=>this.handleChange(e, 'produitTypePrix')} required>
                                        <option selected value="">--Type de Prix--</option>
                                        {produitPuDetail !== '' && <option selected value={produitPuDetail}>Prix détaillant</option>}
                                        {produitPuGro !== '' && <option selected value={produitPuGro}>Prix grossiste</option>}
                                    </select>
                                </div>
                            </div>
                        </>}
                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialState, ...initialStateModal })} type="button" className="btn btn-secondary">Fermer</button>
                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Ajouter</button>
                        </fieldset>
                    </form>
                </ReactModal>

                <ReactModal isOpen={modalClient} ariaHideApp={false}>
                    <h5>Ajout d'un nouveau client</h5>
                    <form method="POST" onSubmit={this.handleSubmitSaveClient}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Nom complet *</label>
                                    <input value={nomClient} onChange={(e) => this.handleChange(e, 'nomClient')} type="text" className="form-control form-control-sm" placeholder="Nom complet *" required />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Société</label>
                                    <input value={societeClient} onChange={(e) => this.handleChange(e, 'societeClient')} type="text" className="form-control form-control-sm" placeholder="Société" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Email</label>
                                    <input value={emailClient} onChange={(e) => this.handleChange(e, 'emailClient')} type="email" className="form-control form-control-sm" placeholder="Email" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className='nx-input-label'>Téléphone</label>
                                    <input value={telephoneClient} onChange={(e) => this.handleChange(e, 'telephoneClient')} type="text" className="form-control form-control-sm" placeholder="Téléphone" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className='nx-input-label'>Adresse</label>
                                    <input value={adresseClient} onChange={(e) => this.handleChange(e, 'adresseClient')} type="text" className="form-control form-control-sm" placeholder="Adresse" />
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: 'end' }}>
                            <button onClick={() => this.setState({ ...initialStateClient })} type="button" className="btn btn-secondary">Annuler</button>
                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                        </div>
                    </form>
                </ReactModal>

                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                    <h5>Traitement ...</h5>

                    <Spinner />
                    <span className="card-text" style={{ display: 'block', textAlign: 'center' }}>
                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                    </span>

                </ReactModal>
            </Head>
        )
    }
}